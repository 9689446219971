export enum PreEnrollmentCampaignStatus {
  CLOSED = 'closed',
  ACTIVE_WITH_PRE_ENROLLMENT = 'active-with-preenrollment',
  ACTIVE_WITHOUT_PRE_ENROLLMENT = 'active-without-preenrollment',
  ACTIVE_WITH_ACCEPTED_PROPOSALS = 'active-with-accepted-proposals',
  NO_CAMPAIGN = 'no-campaign',
  DISABLED = 'disabled',
}

export type StatusPreEnrollmentCampaignType =
  | 'closed'
  | 'active-with-preenrollment'
  | 'active-without-preenrollment'
  | 'active-with-accepted-proposals'
  | 'no-campaign'
  | 'disabled';
