'use client';

import { useCallback, useMemo } from 'react';
import { diffDays } from '@monorepo/utils/dateHelpers';
import { formatReal } from '@monorepo/utils/numberFormatters';
import type { GreetingStatusGuardian } from '../types';
export const useGreetingMessagePreEnrollment = ({
  status,
  enrollmentsOverdues,
  expiredEnrollmentCount,
  totalAmount,
  nextDueDate,
  countSameNextDueDate
}: useGreetingMessagePreEnrollmentProps) => {
  const onlyOverdue = enrollmentsOverdues - expiredEnrollmentCount;
  const messageOndue = useCallback(() => {
    if (expiredEnrollmentCount > 0) {
      if (expiredEnrollmentCount === 1) return <>
            Você tem 1 parcela de <strong>pré-matrícula expirada</strong>.
          </>;
      return <>
          Você tem {expiredEnrollmentCount} parcelas de{' '}
          <strong>pré-matrícula expiradas</strong>.
        </>;
    }
    if (status.isOverduePreEnrollment) {
      if (enrollmentsOverdues === 1) return <>
            Você tem 1 parcela de <strong>pré-matrícula vencida</strong>,
            somando {formatReal(totalAmount)}.
          </>;
      return <>
          Você tem {enrollmentsOverdues} parcelas de{' '}
          <strong>pré-matrícula vencidas</strong>, somando{' '}
          {formatReal(totalAmount)}.
        </>;
    }
    return '';
  }, [enrollmentsOverdues, expiredEnrollmentCount, status.isOverduePreEnrollment, totalAmount]);
  const preEnrollmentOndue = useCallback(() => {
    const diffDate = diffDays(nextDueDate);
    if (diffDate === 0) {
      if (countSameNextDueDate === 1) return '1 parcela de pré-matrícula vence hoje.';
      return `${countSameNextDueDate} parcelas de pré-matrícula vencem hoje.`;
    }
    if (diffDate === 1) {
      if (countSameNextDueDate === 1) return '1 parcela de pré-matrícula vence amanhã.';
      return `${countSameNextDueDate} parcelas de pré-matrícula vencem amanhã.`;
    }
    return `A próxima parcela de pré-matrícula vence em ${diffDate} dias.`;
  }, [countSameNextDueDate, nextDueDate]);
  const messageOverdue = useCallback(() => {
    if (expiredEnrollmentCount > 0) {
      if (expiredEnrollmentCount === 1) return '1 parcela de pré-matrícula expirada.';
      return `${expiredEnrollmentCount} parcelas de pré-matrícula expiradas.`;
    }
    if (status.isOverduePreEnrollment) {
      if (enrollmentsOverdues > 0) {
        if (enrollmentsOverdues === 1) return '1 parcela vencida de pré-matrícula.';
        return `${onlyOverdue} parcelas vencidas são de pré-matrícula.`;
      }
    }
    return preEnrollmentOndue();
  }, [enrollmentsOverdues, expiredEnrollmentCount, onlyOverdue, preEnrollmentOndue, status.isOverduePreEnrollment]);
  const message = useMemo(() => {
    if (status.isOverdueTuition) {
      return messageOverdue();
    }
    return messageOndue();
  }, [messageOndue, messageOverdue, status.isOverdueTuition]);
  return {
    message
  };
};
export type useGreetingMessagePreEnrollmentProps = {
  status: GreetingStatusGuardian;
  enrollmentsOverdues: number;
  expiredEnrollmentCount: number;
  totalAmount: number;
  nextDueDate: string;
  countSameNextDueDate: number;
};