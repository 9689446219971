import { parse, stringify } from 'querystring';
import type { ReadonlyURLSearchParams } from 'next/navigation';

export const stringifyUrl = (input: {
  href: string;
  searchParams?: ReadonlyURLSearchParams | null;
}) => {
  const { href, searchParams } = input;

  const [pathname, queryString] = href.split('?');

  const utm = parse(getUtmSearchParams(searchParams ?? undefined).toString());
  const query = parse(new URLSearchParams(queryString).toString());

  const queryParameters = stringify({ ...query, ...utm });

  const newQuery = queryParameters ? `?${queryParameters}` : '';

  return `${pathname}${newQuery}`;
};

export const getUtmString = (searchParams?: ReadonlyURLSearchParams | null) => {
  const utmString = stringify(
    parse(getUtmSearchParams(searchParams).toString())
  );

  return utmString ? `?${utmString}` : '';
};

export const getUtmSearchParams = (
  searchParams?: ReadonlyURLSearchParams | null
) => {
  const utm_source = searchParams?.get('utm_source');
  const utm_medium = searchParams?.get('utm_medium');
  const utm_campaign = searchParams?.get('utm_campaign');
  const utm_term = searchParams?.get('utm_term');
  const utm_content = searchParams?.get('utm_content');

  return new URLSearchParams({
    ...(utm_source ? { utm_source } : undefined),
    ...(utm_medium ? { utm_medium } : undefined),
    ...(utm_campaign ? { utm_campaign } : undefined),
    ...(utm_term ? { utm_term } : undefined),
    ...(utm_content ? { utm_content } : undefined),
  });
};
