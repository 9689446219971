import React from 'react';
import { unstable_noStore as noStore } from 'next/cache';
import type { UserIdentification } from '@monorepo/interfaces';
import { EventContext, EventContextProvider } from './EventContextProvider';
import { EventDispatcherSDKProvider } from './sdkProvider';
import { booleanParser } from './utils';
export const usePostEvents = () => {
  return React.useContext(EventContext);
};
export const EventDispatcherProvider = ({
  children,
  userIdentificationData
}: {
  children: React.ReactNode;
  userIdentificationData?: UserIdentification | null;
}) => {
  noStore();
  return <EventDispatcherSDKProvider options={{
    mixpanelDebugMode: booleanParser.parse(process.env.MIXPANEL_ENABLE_DEBUG_MODE),
    mixpanelProjectToken: process.env.MIXPANEL_PROJECT_TOKEN,
    unleashProxyUrl: process.env.UNLEASH_PROXY_URL,
    unleashClientKey: process.env.UNLEASH_PROXY_KEY,
    isMixpanelEnabled: booleanParser.parse(process.env.MIXPANEL_ENABLED)
  }} data-sentry-element="EventDispatcherSDKProvider" data-sentry-component="EventDispatcherProvider" data-sentry-source-file="index.tsx">
      <EventContextProvider userIdentificationData={userIdentificationData} data-sentry-element="EventContextProvider" data-sentry-source-file="index.tsx">
        {children}
      </EventContextProvider>
    </EventDispatcherSDKProvider>;
};
export type { NegotiationEventData, MeuIsaacEventData, EnrollmentsEventData } from './types';