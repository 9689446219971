'use client';

import { useMemo } from 'react';

import type { GetInstallmentV2RouteResponse } from '@monorepo/interfaces';
import { useSendEvents } from '@monorepo/events/src/negotiation';
import { EventScope, NegotiationEvents } from '@monorepo/events/src/types';
import { useRouter } from '@monorepo/router';
import {
  groupInstallments,
  orderInstallments,
  splitInstallmentsByStatus,
} from '@monorepo/utils/dashboardHelpers';
import { filterIsNotBrokenPromiseInstallments } from '@monorepo/utils/filterInstallments';

import type { InstallmentItemData } from '../types';
import type { InstallmentsList } from './types';

type Props = {
  tab?: '0' | '1';
  installmentsData?: GetInstallmentV2RouteResponse;
};

export const useInstallmentList = ({ tab = '0', installmentsData }: Props) => {
  const router = useRouter();
  const { sendCardClickEvent } = useSendEvents(EventScope.HomePage);

  const { allOpens: allOpenList, paids: paidList } = useMemo(() => {
    const filteredInstallments = filterIsNotBrokenPromiseInstallments(
      installmentsData?.data
    );
    return splitInstallmentsByStatus(filteredInstallments);
  }, [installmentsData?.data]);

  const allOpenListEntry: InstallmentsList = useMemo(() => {
    const ordered = orderInstallments(allOpenList, 'dueDate', 'asc');
    return groupInstallments(ordered, 'asc');
  }, [allOpenList]);

  const paidListEntry: InstallmentsList = useMemo(() => {
    const ordered = orderInstallments(paidList, 'paidDate', 'desc');
    return groupInstallments(ordered, 'desc');
  }, [paidList]);

  const handleInstallment = (
    data: InstallmentItemData,
    event: NegotiationEvents
  ) => {
    let name = data?.status === 'OPEN' ? 'Parcela a vencer' : 'Parcela paga';
    if (data?.status === 'OPEN' && data?.overdue) {
      event = NegotiationEvents.BILL_OVERDUE;
      name = 'Parcela vencida';
    }

    sendCardClickEvent({
      event,
      data: {
        name,
        receivable_type: data?.receivableType,
        installment_id: data?.id,
        type: data?.type,
      },
    });

    router.push(`/negociacao/parcela/${data?.id}?tab=${tab}`);
  };

  return {
    allOpenListEntry,
    paidListEntry,
    handleInstallment,
  };
};
