'use client';

import type { StatusPreEnrollmentCampaignType } from '@monorepo/interfaces/preEnrollment/status';
import { PreEnrollmentCampaignStatus } from '@monorepo/interfaces/preEnrollment/status';

import type { BannerData } from './types';
import PreEnrolmenteNoCampaign from './pre-enrollment-no-campaign.svg';
import PreEnrollmentImage from './pre-enrollment-stamp.svg';

const copy: Record<
  Exclude<StatusPreEnrollmentCampaignType, undefined>,
  Omit<BannerData, 'showBanner'>
> = {
  [PreEnrollmentCampaignStatus.CLOSED]: {
    title: 'Campanha de matrícula encerrada',
    subtitle:
      'A campanha de matrículas 2025 terminou, mas você pode contatar diretamente a escola se quiser se matricular.',
    button: 'Ok, entendi',
    bannerType: 'closed',
    src: '',
    showBanner: true,
  },
  [PreEnrollmentCampaignStatus.ACTIVE_WITH_PRE_ENROLLMENT]: {
    title: 'Sua escola iniciou as matrículas',
    subtitle:
      'Matrículas abertas com condições especiais. Tem interesse em rematricular?',
    button: 'Tenho interesse',
    bannerType: 'open_with_pre_enrollment',
    src: PreEnrollmentImage,
    showBanner: true,
  },
  [PreEnrollmentCampaignStatus.ACTIVE_WITHOUT_PRE_ENROLLMENT]: {
    title: 'Sua escola iniciou as matrículas',
    subtitle: 'Você tem interesse em rematricular?',
    button: 'Tenho interesse',
    bannerType: 'open_without_pre_enrollment',
    src: PreEnrollmentImage,
    showBanner: true,
  },
  [PreEnrollmentCampaignStatus.ACTIVE_WITH_ACCEPTED_PROPOSALS]: {
    title: 'Matrículas 2025',
    subtitle:
      'Saiba como está o processo de matrícula para o ano letivo de 2025.',
    button: 'Acompanhar etapas',
    src: PreEnrollmentImage,
    bannerType: 'open_showed_interest',
    showBanner: true,
  },
  [PreEnrollmentCampaignStatus.NO_CAMPAIGN]: {
    title: 'Matrículas 2025',
    subtitle:
      'Saiba como está o processo de matrícula para o ano letivo de 2025.',
    button: 'Acompanhar etapas',
    src: PreEnrolmenteNoCampaign,
    bannerType: 'no-campaign',
    showBanner: true,
  },
  [PreEnrollmentCampaignStatus.DISABLED]: {
    showBanner: false,
  },
};

export const usePreEnrollmentsBanners = (
  status: PreEnrollmentCampaignStatus | undefined
) => {
  if (!status) return copy[PreEnrollmentCampaignStatus.DISABLED] as BannerData;

  const bannerData = copy[status] as BannerData;

  return bannerData;
};
