'use client';

import React from 'react';
import { EventDispatcherProvider as OlaIsaacEventDispatcherProvider } from '@olaisaac/event-dispatcher-sdk';
type Props = {
  options: {
    mixpanelDebugMode?: boolean;
    mixpanelProjectToken?: string;
    unleashProxyUrl?: string;
    unleashClientKey?: string;
    isMixpanelEnabled?: boolean;
  };
};
export const EventDispatcherSDKProvider = ({
  options,
  children
}: React.PropsWithChildren<Props>) => {
  const {
    mixpanelDebugMode,
    mixpanelProjectToken,
    isMixpanelEnabled,
    unleashProxyUrl,
    unleashClientKey
  } = options;
  return <OlaIsaacEventDispatcherProvider options={{
    mixpanelDebugMode,
    mixpanelProjectToken
  }} disabledOnServer unleashProxyUrl={unleashProxyUrl ?? ''} unleashClientKey={unleashClientKey ?? ''} isMixpanelEnabled={isMixpanelEnabled} data-sentry-element="OlaIsaacEventDispatcherProvider" data-sentry-component="EventDispatcherSDKProvider" data-sentry-source-file="sdkProvider.tsx">
      {children}
    </OlaIsaacEventDispatcherProvider>;
};