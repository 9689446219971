'use client';

import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { InstallmentListProps } from './types';
import InstallmentEntry, { SkeletonInstallmentEntry } from './InstallmentEntry';
import NoInstallments from './NoInstallments';
export const InstallmentList = ({
  data,
  state,
  onClick,
  error = false,
  loading = false
}: InstallmentListProps) => {
  if (loading) {
    return <SkeletonInstallmentEntry />;
  }
  if (!data.length) return <NoInstallments state={state} error={error} />;
  return <div data-testid="installment-list" className="pb-8" data-sentry-component="InstallmentList" data-sentry-source-file="index.tsx">
      {data.map(element => <InstallmentEntry data={element} key={`installment-entry-${element.title}-${uuidv4()}`} onClick={onClick} />)}
    </div>;
};