'use client';

import type { VariantProps } from 'class-variance-authority';
import React from 'react';
import { useMaskito } from '@maskito/react';
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { cn } from '@srcTailwindUi/lib/utils';
import type { MaskTypes } from './utils';
import { customMask } from './utils';
import { inputVariants } from './variants';
type InputProps = {
  error?: boolean;
  mask?: MaskTypes;
} & React.InputHTMLAttributes<HTMLInputElement> & VariantProps<typeof inputVariants>;
const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  className,
  type,
  variant,
  scale,
  error,
  ...props
}, _) => {
  const inputRef = useMaskito({
    options: customMask(props.mask)
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };
  return <div className="relative">
        <input type={showPassword ? 'text' : type} className={cn(inputVariants({
      variant: error ? 'error' : variant,
      scale,
      className
    }))} ref={inputRef} {...props} />
        {type === 'password' ? <button type="button" onClick={togglePasswordVisibility} className="absolute inset-y-0 bottom-0 right-0 top-0 flex items-center pr-4">
            {showPassword ? <Icon path={mdiEyeOutline} size="18px" /> : <Icon path={mdiEyeOffOutline} size="18px" />}
          </button> : null}
      </div>;
});
Input.displayName = 'Input';
export { Input, inputVariants, type InputProps };