'use client';

import { useMemo } from 'react';

import type { GetInstallmentV2RouteResponse } from '@monorepo/interfaces';
import { splitInstallmentsByStatusAndReceivableType } from '@monorepo/utils/dashboardHelpers';
import { filterIsNotBrokenPromiseOrAwaitingInstallments } from '@monorepo/utils/filterInstallments';

export const useGreetingInvoiceNegotiation = ({
  installmentsData,
}: {
  installmentsData?: GetInstallmentV2RouteResponse;
}) => {
  const { enrollments, tuitions } = useMemo(() => {
    const filteredInstallments = filterIsNotBrokenPromiseOrAwaitingInstallments(
      installmentsData?.data
    );
    return splitInstallmentsByStatusAndReceivableType(filteredInstallments);
  }, [installmentsData?.data]);

  return {
    enrollments,
    tuitions,
  };
};
