import type { ReactNode } from 'react';
import { Heading } from '@gravity/heading';
import { cn } from '@srcTailwindUi/lib/utils';
import type { Variant } from './types';
import { titleVariants } from './variants';
type BannerTitleProps = {
  title: ReactNode;
  variant?: Variant;
  nowrap?: boolean;
  font?: string;
};
export function BannerTitle({
  variant = 'default',
  nowrap = false,
  title
}: BannerTitleProps) {
  return <Heading variant="heading-h4-medium" className={cn(titleVariants({
    variant
  }), nowrap ? 'text-nowrap' : '')} data-sentry-element="Heading" data-sentry-component="BannerTitle" data-sentry-source-file="BannerTitle.tsx">
      {title}
    </Heading>;
}