'use client';

import { useWindowSize } from '@monorepo/utils/hooks';
import type { DiscountCampaignData } from './types';
import Image from '../assets/discountCampaign.svg';
export const useDiscountCampaignBanner = () => {
  const {
    width
  } = useWindowSize();
  return {
    title: <strong>Negocie hoje com até 100% de descontos em juros e multa!</strong>,
    subtitle: 'Aproveite a melhor oportunidade para começar 2024 no azul!',
    button: 'Negociar',
    href: '/negociacao/parcelas-vencidas',
    src: Image.src,
    titleFontSize: width && width > 635 ? 'small' : 'default',
    subtitleMarginTop: width && width > 635 ? 'mt-3' : 'mt-2'
  } as DiscountCampaignData;
};