import type { IVariant } from 'unleash-proxy-client';
import { useFlag, useVariant } from '@unleash/nextjs/client';

import type { UnleashFlags } from './flags';

export const useUnleashFlag = (feature: UnleashFlags) => {
  return useFlag(feature);
};

export const useUnleashVariant = (feature: UnleashFlags): IVariant => {
  return useVariant(feature);
};
