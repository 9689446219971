'use client';

import type { ZodType } from 'zod';
import React from 'react';
import { Button } from '@gravity/button';
import { Heading } from '@gravity/heading';
import { Text } from '@gravity/text';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { cpfRegex, isValidCPFWithMod11 } from '@monorepo/utils/isValidCPF';
import { useImpersonateStore } from '@monorepo/utils/stores';
import { ControlledFormField } from '@srcTailwindUi/components/ControlledFormField';
import { Form } from '@srcTailwindUi/components/Form';
import { ResponsiveDialog } from '@srcTailwindUi/components/ResponsiveDialog';
export type FormImpersonateData = {
  cpf?: string;
};
export const ImpersonateDialog = () => {
  const schema: ZodType<FormImpersonateData> = z.object({
    cpf: z.string().regex(cpfRegex, {
      message: 'Insira um CPF válido'
    }).optional()
  }).refine(data => !data.cpf ? true : isValidCPFWithMod11(data.cpf ?? ''), {
    message: 'Insira um CPF válido',
    path: ['cpf']
  });
  const form = useForm<FormImpersonateData>({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      cpf: undefined
    }
  });
  const {
    setImpersonatedTaxId,
    isOpenImpersonateDialog,
    setIsOpenImpersonateDialog
  } = useImpersonateStore();
  const closeImpersonateDialog = () => setIsOpenImpersonateDialog(false);
  const handlePrimaryButton = (data: FormImpersonateData) => {
    setImpersonatedTaxId(data.cpf?.replace(/\D/g, ''));
    closeImpersonateDialog();
  };
  return <ResponsiveDialog open={isOpenImpersonateDialog} onClose={closeImpersonateDialog} data-sentry-element="ResponsiveDialog" data-sentry-component="ImpersonateDialog" data-sentry-source-file="index.tsx">
      <Form {...form} data-sentry-element="Form" data-sentry-source-file="index.tsx">
        <form className="flex w-full flex-col gap-6" onSubmit={form.handleSubmit(handlePrimaryButton)}>
          <div className="flex w-full flex-col gap-6">
            <div className="flex w-full flex-col gap-2">
              <Heading variant="heading-h2-regular" data-sentry-element="Heading" data-sentry-source-file="index.tsx">Impersonate</Heading>
              <Text variant="body-1-regular" data-sentry-element="Text" data-sentry-source-file="index.tsx">
                Defina um CPF para acessar o meu Isaac
              </Text>
            </div>
            <ControlledFormField id="cpf" control={form.control} name="cpf" type="string" variant="primary" inputMode="numeric" mask="cpf" placeholder="000.000.000-00" data-sentry-element="ControlledFormField" data-sentry-source-file="index.tsx" />
          </div>
          <div className="mt-2 flex w-[635px] -translate-x-6 border-b border-solid border-gray-100" />
          <div className="flex w-full flex-col">
            <Button className="mb-3" type="submit" data-sentry-element="Button" data-sentry-source-file="index.tsx">
              Acessar
            </Button>
          </div>
        </form>
      </Form>
    </ResponsiveDialog>;
};