'use client';

import type { VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cn } from '@srcTailwindUi/lib/utils';
import { Spinner } from '../Spinner/index';
import { buttonVariants } from './variants';
export type ButtonProps = {
  asChild?: boolean;
  isLoading?: boolean;
  icon?: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement> & VariantProps<typeof buttonVariants>;
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  size,
  asChild = false,
  isLoading,
  children,
  onClick,
  icon,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : 'button';
  const [clicked, setClicked] = React.useState(false);
  React.useEffect(() => {
    if (!isLoading) setClicked(false);
  }, [isLoading]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isLoading) onClick?.(event);
    setClicked(true);
  };
  return <Comp className={cn(buttonVariants({
    variant,
    size,
    className
  }), isLoading && clicked ? 'text-transparent disabled:text-transparent' : '')} ref={ref} onClick={handleClick} {...props}>
        {icon && <div className="flex h-10 w-10 justify-center">{icon}</div>}
        {children}
        <Spinner variant={variant} className={isLoading && clicked ? 'absolute flex' : 'hidden'} />
      </Comp>;
});
Button.displayName = 'Button';
export { Button, buttonVariants };