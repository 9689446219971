import type { ImageProps } from 'next/image';
import Image from 'next/image';
import { cn } from '@srcTailwindUi/lib/utils';
type BannerImageProps = {
  src: ImageProps['src'];
  smallImage?: boolean;
};
export function BannerImage({
  smallImage = false,
  src
}: BannerImageProps) {
  return <Image quality={60} priority={true} alt="banner" className={cn('relative right-0 w-auto sm:absolute sm:mr-2', smallImage ? 'max-h-[104px] sm:h-[104px]' : 'max-h-[172px] sm:h-[128px]')} src={src as string} data-sentry-element="Image" data-sentry-component="BannerImage" data-sentry-source-file="BannerImage.tsx" />;
}