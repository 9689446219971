'use client';

import React from 'react';
import { Text } from '@gravity/text';
import { Info } from 'lucide-react';
import type { HasContractsToApproveData } from '@monorepo/interfaces';
import { usePostEvents } from '@monorepo/events';
import { useRouter } from '@monorepo/router';
const FIDCAlert = ({
  fidcData
}: {
  fidcData?: HasContractsToApproveData;
}) => {
  const {
    push
  } = useRouter();
  const {
    mutate: postEvent
  } = usePostEvents();
  if (!fidcData?.hasContractsToApprove) return null;
  return <div className="flex items-center justify-between bg-coral-200 p-6" data-sentry-component="FIDCAlert" data-sentry-source-file="index.tsx">
      <div className="flex gap-3">
        <Info className="h-6 w-6 text-coral-700" data-sentry-element="Info" data-sentry-source-file="index.tsx" />
        <Text variant="body-2-medium" className="text-gray-800" data-sentry-element="Text" data-sentry-source-file="index.tsx">
          Confirme seus dados para proteger seu cadastro
        </Text>
      </div>
      <button className="flex cursor-pointer items-center border-none bg-transparent text-sm font-normal tracking-[0.25px] text-blue-600" onClick={() => {
      push('/revisao/identidade');
      postEvent({
        app: 'fidc-confirmation',
        action: 'click',
        event: 'click_menu_FIDC'
      });
    }}>
        Confirmar
      </button>
    </div>;
};
export default FIDCAlert;