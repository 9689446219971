import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { SessionStorageKeys } from '@monorepo/interfaces';

type ImpersonateStore = {
  impersonatedTaxId?: string;
  isOpenImpersonateDialog: boolean;
  setImpersonatedTaxId: (impersonatedTaxId?: string) => void;
  setIsOpenImpersonateDialog: (isOpenImpersonateDialog: boolean) => void;
  reset: () => void;
};

export const initialState = {
  impersonatedTaxId: undefined,
  isOpenImpersonateDialog: false,
};

export const useImpersonateStore = create(
  persist<ImpersonateStore>(
    (set) => ({
      ...initialState,
      setImpersonatedTaxId: (impersonatedTaxId?: string) =>
        set((state) => ({
          ...state,
          impersonatedTaxId,
        })),
      setIsOpenImpersonateDialog: (isOpenImpersonateDialog: boolean) =>
        set((state) => ({ ...state, isOpenImpersonateDialog })),
      reset: () => set(initialState),
    }),
    {
      version: 1,
      name: SessionStorageKeys.IMPERSONATE_STORE,
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
