import dayjs from 'dayjs';

import type { InstallmentStatus, InstallmentV2 } from '@monorepo/interfaces';

type WithStatus = { status: InstallmentStatus };

export const isOverdue = (installment: InstallmentV2) => {
  return installment.overdue && installment.status !== 'PAID';
};

export const isAwaiting = (installment: InstallmentV2) =>
  installment.down_payment_waiting_payment ?? installment.status === 'AWAITING';

export const isBrokenPromise = (installment: { status: InstallmentStatus }) =>
  installment.status === 'BROKEN_PROMISE';

export const isOpen = (installment: InstallmentV2) =>
  installment.status === 'OPEN' && !installment.overdue;

export const isPaid = (installment: WithStatus) =>
  installment.status === 'PAID';

export const isEnrollment = (installment: InstallmentV2) =>
  installment.receivable_type === 'ENROLLMENT';

export const getOldestInstallment = (installments?: InstallmentV2[]) => {
  if (installments?.length) {
    return installments?.reduce((a, b) =>
      dayjs(a.due_date).isBefore(dayjs(b.due_date)) ? a : b
    );
  }
  return undefined;
};

export const getNewestInstallmentByPaidDate = (
  installments?: InstallmentV2[]
) => {
  if (!installments?.length) return;

  if (installments?.length) {
    return installments.reduce((a, b) =>
      dayjs(a.paid_date).isBefore(dayjs(b.paid_date)) ? b : a
    );
  }
  return undefined;
};

export const filterLastPaidInstallment = (installments?: InstallmentV2[]) => {
  if (!installments?.length) return;

  const paidInstallments = installments.filter((installment) => {
    return installment.status === 'PAID';
  });
  const lastPaidInstallment = paidInstallments.length
    ? getNewestInstallmentByPaidDate(paidInstallments)
    : undefined;
  return lastPaidInstallment;
};

export const filterOldestOverdueInstallment = (
  installments?: InstallmentV2[]
) => {
  if (!installments?.length) return;

  const overdueInstallments = installments.filter((installment) => {
    return installment.overdue;
  });
  const firstOverdueInstallment = overdueInstallments.length
    ? getOldestInstallment(overdueInstallments)
    : undefined;
  return firstOverdueInstallment;
};

export const filterNextOpenInstallment = (installments?: InstallmentV2[]) => {
  if (!installments?.length) return;
  const openInstallments = installments.filter((installment) => {
    return installment.status === 'OPEN' && !isOverdue(installment);
  });
  const nextOpenInstallment = openInstallments.length
    ? getOldestInstallment(openInstallments)
    : undefined;
  return nextOpenInstallment;
};

export const uniqueArray = (array: string[]) => [...new Set(array)];

export const filterIsNotBrokenPromiseInstallments = (
  installments?: InstallmentV2[]
) => installments?.filter((installment) => !isBrokenPromise(installment)) ?? [];

export const filterIsNotBrokenPromiseOrAwaitingInstallments = (
  installments?: InstallmentV2[]
) =>
  installments?.filter(
    (installment) => !isBrokenPromise(installment) && !isAwaiting(installment)
  ) ?? [];

export const filterIsNotEnrollmentInstallments = (
  installments?: InstallmentV2[]
) => installments?.filter((installment) => !isEnrollment(installment)) ?? [];
