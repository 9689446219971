import type { MotionValue } from 'framer-motion';
import { useTransform } from 'framer-motion';

export const useAnimation = (
  scrollY: MotionValue<number>,
  scrollable?: boolean,
  animate?: boolean
) => {
  const yDefault = useTransform(scrollY, [0, 50], [animate ? '56px' : 56, 0]);

  const yScrollable = useTransform(
    scrollY,
    [0, 50],
    [animate ? '56px' : 0, '-76px']
  );

  const opacity = useTransform(scrollY, [0, 50], [1, 0]);

  return {
    y: scrollable ? yScrollable : yDefault,
    opacity: scrollable ? opacity : 1,
  };
};
