import type {
  EventScope,
  NegotiationEventData,
  NegotiationEvents,
} from '@srcEvents/types';
import { usePostEvents } from '@srcEvents/index';
import { EventName } from '@srcEvents/types';

type Params = {
  event: NegotiationEvents;
  data?: NegotiationEventData;
};

export const useSendEvents = (scope: EventScope) => {
  const { mutate } = usePostEvents();

  const sendEvent = (event: EventName, params: Params) => {
    mutate({
      action: event === EventName.PAGE_VIEWED ? 'page_view' : 'click',
      app: 'negotiation',
      event,
      entity: params.event,
      scope,
      data: params.data,
    });
  };

  return {
    sendButtonClickEvent: (params: Params) =>
      sendEvent(EventName.BUTTON_CLICKED, params),
    sendPageViewedEvent: (params: Params) =>
      sendEvent(EventName.PAGE_VIEWED, params),
    sendBannerClickEvent: (params: Params) =>
      sendEvent(EventName.BANNER_CLICKED, params),
    sendTabClickEvent: (params: Params) =>
      sendEvent(EventName.TAB_CLICKED, params),
    sendCardClickEvent: (params: Params) =>
      sendEvent(EventName.CARD_CLICKED, params),
    sendCheckSelectEvent: (params: Params) =>
      sendEvent(EventName.CHECK_SELECTED, params),
    sendCheckUnselectEvent: (params: Params) =>
      sendEvent(EventName.CHECK_UNSELECTED, params),
  };
};
