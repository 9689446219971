import NextLink from 'next/link';
import { Link as DSLink } from '@gravity/link';
type LinkProps = {
  href: string;
  children: React.ReactNode;
  underline?: 'none' | 'hover' | 'always' | 'auto';
  className?: string;
  size?: 1 | 2 | 3 | undefined;
  weight?: 'light' | 'semibold';
  target?: string;
};
export const Link = ({
  href,
  underline = 'none',
  ...props
}: LinkProps) => {
  return <NextLink href={href} passHref legacyBehavior data-sentry-element="NextLink" data-sentry-component="Link" data-sentry-source-file="index.tsx">
      <DSLink underline={underline} {...props} data-sentry-element="DSLink" data-sentry-source-file="index.tsx" />
    </NextLink>;
};
export default Link;