import type { MaskitoOptions } from '@maskito/core';

const masks = {
  cpf: [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  phone: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  zipCode: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
};

export type MaskTypes = keyof typeof masks;

export const customMask = (
  mask?: MaskTypes | (RegExp | string)[] | RegExp
): MaskitoOptions | undefined => {
  if (!mask) return;
  if (typeof mask !== 'string') return { mask }; // Retorna a máscara se não for uma string

  return { mask: masks[mask] };
};
