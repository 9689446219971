import type { StringOrUndefined } from './typescript/overloadPrimitives';

export const isValidCPF = (taxId: string) => {
  return taxId.length == 11 && !!/\d{11}/.exec(taxId);
};

export const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

export const isValidCPFWithMod11 = (taxId: string) => {
  taxId = taxId.replace(/[^\d]+/g, '');
  if (taxId.length !== 11 || !!/(\d)\1{10}/.exec(taxId)) return false;
  const cpf = taxId.split('').map((el) => +el);
  const rest = (count: number) =>
    ((cpf
      .slice(0, count - 12)
      .reduce((soma, el, index) => soma + el * (count - index), 0) *
      10) %
      11) %
    10;
  return rest(10) === cpf[9] && rest(11) === cpf[10];
};

export function formatCPF<T extends string | undefined>(
  taxId: T
): StringOrUndefined<T>;
export function formatCPF(taxId?: string): string | undefined {
  if (!taxId) return;
  const taxIdOnlyDigits = taxId.replace(/[^\d]+/g, '');

  return taxIdOnlyDigits.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}
