'use client';

import type { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';
import React from 'react';
import type { InputProps } from '@srcTailwindUi/components/Input';
import { FormControl, FormDescription, FormItem, FormLabel, FormMessage, useFormField } from '@srcTailwindUi/components/Form';
import { Input } from '@srcTailwindUi/components/Input';
type ControlledFormItemProps<TFieldValues extends FieldValues> = {
  field: ControllerRenderProps<TFieldValues, Path<TFieldValues>>;
  label?: React.ReactNode;
  description?: React.ReactNode;
} & InputProps;
const ControlledFormItem = <TFieldValues extends FieldValues,>({
  field,
  label,
  description,
  ...props
}: ControlledFormItemProps<TFieldValues>) => {
  const {
    error,
    setValue,
    name
  } = useFormField();
  return <FormItem className="flex w-full flex-col gap-6" data-sentry-element="FormItem" data-sentry-component="ControlledFormItem" data-sentry-source-file="index.tsx">
      <div className="flex w-full flex-col gap-2">
        {label ? <FormLabel className="text-gray-800">{label}</FormLabel> : null}
        <FormControl data-sentry-element="FormControl" data-sentry-source-file="index.tsx">
          <Input {...props} {...field} error={!!error} onInput={e => {
          setValue(name, e.currentTarget.value, {
            shouldDirty: true,
            shouldTouch: true
          });
        }} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
        </FormControl>
        <FormMessage data-sentry-element="FormMessage" data-sentry-source-file="index.tsx" />
      </div>
      {description ? <FormDescription>{description}</FormDescription> : null}
    </FormItem>;
};
export { ControlledFormItem, type ControlledFormItemProps };