import React from 'react';
export const useElementHeightById = (id: string, resizedComponentToCheck?: string): {
  height: number;
} => {
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(false);
  const element = document.getElementById(id);
  const resizedElement = document.getElementById(resizedComponentToCheck ?? id);
  React.useEffect(() => {
    const handleResize = () => {
      if (element) setHeight(element.offsetHeight);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [element, id, resizedComponentToCheck]);
  React.useEffect(() => {
    const handleResize = () => {
      if (element) setHeight(element.offsetHeight);
    };
    if (resizedElement) {
      observeElementHeight(resizedElement, handleResize);
    }
    if (element) {
      observeElementHeight(element, () => {
        if (ref.current) return;
        handleResize();
        ref.current = true;
      });
    }
  }, [element, resizedElement]);
  return {
    height
  };
};
export const useElementHeight = (): [number, React.RefObject<HTMLDivElement>] => {
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const handleResize = () => {
      if (ref.current) setHeight(ref.current.offsetHeight);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return [height, ref];
};
export const observeElementHeight = (element: HTMLElement, callback: () => void) => {
  const observer = new ResizeObserver(() => {
    callback();
  });
  observer.observe(element);
};