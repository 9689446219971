'use client';

import React from 'react';
import { useSendEvents } from '@monorepo/events/src/negotiation';
import { EventScope, NegotiationEvents } from '@monorepo/events/src/types';
import { useRouter } from '@monorepo/router';
import { BannerCard } from '@srcTailwindUi/components/BannerCard';
import { useDiscountCampaignBanner } from './useDiscountCampaignBanner';
const DiscountCampaignBanner: React.FC = () => {
  const bannerData = useDiscountCampaignBanner();
  const {
    sendButtonClickEvent
  } = useSendEvents(EventScope.HomePage);
  const router = useRouter();
  const {
    title,
    secondTitle,
    subtitle,
    button,
    href,
    src,
    message,
    titleFontSize,
    subtitleMarginTop
  } = bannerData;
  return <BannerCard title={title} secondTitle={secondTitle} subtitle={subtitle} message={message} buttonText={button} variant="yellow" fontSize={titleFontSize} smallImage src={src} contentWidth="100%" subtitleMarginTop={subtitleMarginTop} onClick={() => {
    router.push(href, {
      useWindowOpen: true
    });
    sendButtonClickEvent({
      event: NegotiationEvents.DISCOUNT_CAMPAIGN_BANNER,
      data: {
        name: 'Banner tela inicial'
      }
    });
  }} data-sentry-element="BannerCard" data-sentry-component="DiscountCampaignBanner" data-sentry-source-file="index.tsx" />;
};
export default DiscountCampaignBanner;