'use client';

import { useMemo } from 'react';

import type { GetInstallmentV2RouteResponse } from '@monorepo/interfaces';

import type { GreetingStatusGuardian } from '../../types';
import { useGreetingInformation } from '../../useGreetingInformation';
import { useGreetingInvoiceNegotiation } from '../../useGreetingInvoiceNegotiation';
import { useGreetingMessagePreEnrollment } from '../../useGreetingMessagePreEnrollment';
import { useGreetingMessageTuition } from '../../useGreetingMessageTuition';

export const useNegotiationMessages = ({
  installmentsData,
  isError,
}: {
  installmentsData?: GetInstallmentV2RouteResponse;
  isError: boolean;
}) => {
  const { tuitions, enrollments } = useGreetingInvoiceNegotiation({
    installmentsData,
  });

  const {
    nextTuitionDueDateToPay,
    nextPreEnrollmentDueDateToPay,
    overdueAmount,
    expiredPreEnrollment,
    sameNextDueDateEnrollment,
  } = useGreetingInformation({ enrollments, tuitions });

  const status: GreetingStatusGuardian = {
    isOverdueTuition: tuitions.overdues.length > 0,
    isOverduePreEnrollment: enrollments.overdues.length > 0,
  };

  const { message: tuitionMessage } = useGreetingMessageTuition({
    error: isError,
    status,
    tuitionsOverdues: tuitions.overdues.length,
    enrollmentsOverdues: enrollments.overdues.length,
    expiredEnrollmentCount: expiredPreEnrollment.length,
    totalAmount: overdueAmount,
    nextTuitionDueDateToPay,
    nextPreEnrollmentDueDateToPay,
  });

  const { message: preEnrollmentMessage } = useGreetingMessagePreEnrollment({
    status,
    enrollmentsOverdues: enrollments.overdues.length,
    countSameNextDueDate: sameNextDueDateEnrollment,
    expiredEnrollmentCount: expiredPreEnrollment.length,
    totalAmount: overdueAmount,
    nextDueDate: nextPreEnrollmentDueDateToPay,
  });

  const messages = useMemo(() => {
    const havePreEnrollment = enrollments.allOpens.length > 0;

    if (status.isOverdueTuition) {
      return {
        firstMessage: tuitionMessage,
        secondMessage: havePreEnrollment ? preEnrollmentMessage : '',
      };
    }
    if (status.isOverduePreEnrollment) {
      return {
        firstMessage: preEnrollmentMessage,
        secondMessage: tuitionMessage,
      };
    }
    return {
      firstMessage: tuitionMessage,
      secondMessage: '',
    };
  }, [
    enrollments.allOpens.length,
    preEnrollmentMessage,
    status.isOverduePreEnrollment,
    status.isOverdueTuition,
    tuitionMessage,
  ]);

  return {
    messages,
  };
};
