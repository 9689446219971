import type { Applications } from '../../types';

export const getScope = (app: Applications) => {
  const categoriesAndGroup = {
    auth: 'auth',
    'fidc-confirmation': 'fidc',
    insurance: 'insurance',
    'matricula-app': 'matricula',
    'pre-matricula': 'matricula',
    'meu-isaac': 'guardians',
    negotiation: 'guardians',
  };

  return categoriesAndGroup[app];
};
