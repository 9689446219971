'use client';

import React from 'react';
import type { GetPreEnrollmentStatus, GetStudentsInEnrollment } from '@monorepo/interfaces';
import { usePostEvents } from '@monorepo/events';
import { PreEnrollmentCampaignStatus } from '@monorepo/interfaces/preEnrollment/status';
import { useRouter } from '@monorepo/router';
import type { Variant } from '../../Banner/types';
import { Banner } from '../../Banner';
import { usePreEnrollmentsBanners } from './usePreEnrollmentBanners';
export const PreEnrollmentsBanners = ({
  studentsInEnrollment,
  preEnrollmentStatus,
  isLoadingGetPreEnrollmentStatus,
  confirmEnrollmentInterest,
  isLoadingConfirmEnrollmentInterest
}: {
  studentsInEnrollment?: GetStudentsInEnrollment;
  preEnrollmentStatus?: GetPreEnrollmentStatus;
  isLoadingGetPreEnrollmentStatus: boolean;
  confirmEnrollmentInterest(): void;
  isLoadingConfirmEnrollmentInterest: boolean;
}) => {
  const [showBanner, setShowBanner] = React.useState<boolean>(true);
  const router = useRouter();
  const {
    mutate: postEvent
  } = usePostEvents();
  const bannerData = usePreEnrollmentsBanners(preEnrollmentStatus?.status);
  if (!bannerData.showBanner) return null;
  if (studentsInEnrollment?.length === 0 && bannerData.bannerType === PreEnrollmentCampaignStatus.NO_CAMPAIGN) return null;
  const {
    title,
    subtitle,
    button,
    src,
    bannerType
  } = bannerData;
  if (bannerType === PreEnrollmentCampaignStatus.DISABLED) {
    return null;
  }
  const clickButton = () => {
    if (bannerType === PreEnrollmentCampaignStatus.CLOSED) {
      setShowBanner(false);
      return;
    }
    if (bannerType !== PreEnrollmentCampaignStatus.NO_CAMPAIGN) {
      postEvent({
        app: 'pre-matricula',
        event: 'Button_Clicked',
        scope: 'Matrícula',
        action: 'click',
        data: {
          button_name: 'MI - Tenho interesse pelo banner'
        }
      });
    } else {
      if (studentsInEnrollment?.length === 1) {
        router.push(`matricula/aluno/${studentsInEnrollment[0]?.studentId}`, {
          useWindowOpen: true
        });
        postEvent({
          app: 'pre-matricula',
          event: 'Button_Clicked',
          scope: 'Matrícula',
          action: 'click',
          data: {
            button_name: 'MI - Campanha encerrada'
          }
        });
      }
      router.push('pre-matricula/contratos', {
        useWindowOpen: true
      });
    }
    confirmEnrollmentInterest();
  };
  const variants: Record<string, Variant> = {
    root: bannerType === PreEnrollmentCampaignStatus.NO_CAMPAIGN ? 'lightBlue' : 'hawkesBlue',
    title: bannerType === PreEnrollmentCampaignStatus.NO_CAMPAIGN ? 'lightBlue' : 'hawkesBlue',
    subtitle: bannerType === PreEnrollmentCampaignStatus.NO_CAMPAIGN ? 'gray' : 'hawkesBlue',
    button: bannerType === PreEnrollmentCampaignStatus.NO_CAMPAIGN ? 'lightBlue' : 'hawkesBlue'
  };
  if (!showBanner || isLoadingGetPreEnrollmentStatus) return null;
  return <Banner.Root variant={variants.root} data-sentry-element="unknown" data-sentry-component="PreEnrollmentsBanners" data-sentry-source-file="index.tsx">
      <Banner.Content data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <Banner.Section contentWidth={!src ? 'full' : ''} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
          <Banner.Title title={title} variant={variants.title} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
          <Banner.Subtitle fontSizeDesktop="small" subtitle={subtitle} variant={variants.subtitle} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
        </Banner.Section>
        {src ? <Banner.Image src={src} smallImage /> : null}
      </Banner.Content>
      <Banner.Footer data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <Banner.Button loading={isLoadingGetPreEnrollmentStatus || isLoadingConfirmEnrollmentInterest} label={button} onClick={clickButton} variant={variants.button} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
      </Banner.Footer>
    </Banner.Root>;
};