import type { StringOrUndefined } from './typescript/overloadPrimitives';

export function toTitleCase<T extends string | undefined>(
  taxId: T
): StringOrUndefined<T>;
export function toTitleCase(str?: string) {
  if (!str) return;
  return str.replace(/[A-Za-zÀ-ÖØ-öø-ÿ]*/g, function title(txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}
