import type { Applications } from '../../types';

export const getAppByPath = (pathname?: string | null): Applications => {
  if (!pathname) return 'meu-isaac';
  if (pathname.includes('/auth')) return 'auth';
  if (pathname.includes('/matricula')) return 'matricula-app';
  if (pathname.includes('/negociacao')) return 'negotiation';
  if (pathname.includes('/seguro-familiar')) return 'insurance';
  if (pathname.includes('/revisao')) return 'fidc-confirmation';
  return 'meu-isaac';
};
