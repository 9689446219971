'use client';

import { useMemo } from 'react';

import {
  countInstallmentWithSameDueDate,
  nextInstallmentToPay,
} from '@monorepo/utils/dashboardHelpers';
import { diffDays } from '@monorepo/utils/dateHelpers';

import type { SplitInstallments } from '../types';

export const useGreetingInformation = ({
  enrollments,
  tuitions,
}: useGreetingInformationProps) => {
  const nextTuitionDueDateToPay = useMemo(
    () =>
      nextInstallmentToPay(
        tuitions.overdues,
        tuitions.opens,
        tuitions.opensToday
      )?.dueDate ?? '',
    [tuitions.opens, tuitions.opensToday, tuitions.overdues]
  );

  const nextPreEnrollmentDueDateToPay = useMemo(
    () =>
      nextInstallmentToPay(
        enrollments.overdues,
        enrollments.opens,
        enrollments.opensToday
      )?.dueDate ?? '',
    [enrollments.opens, enrollments.opensToday, enrollments.overdues]
  );

  const sameNextDueDateEnrollment = useMemo(() => {
    if (diffDays(nextPreEnrollmentDueDateToPay) === 0)
      return enrollments.opensToday.length;
    return countInstallmentWithSameDueDate(
      nextPreEnrollmentDueDateToPay,
      enrollments.allOpens
    );
  }, [
    enrollments.allOpens,
    enrollments.opensToday.length,
    nextPreEnrollmentDueDateToPay,
  ]);

  const expiredPreEnrollment = useMemo(
    () => enrollments.overdues.filter(({ expired }) => expired),
    [enrollments.overdues]
  );

  const overdueAmount = useMemo(() => {
    const totalAmountPreEnrollment = enrollments.overdues.reduce(
      (prev, curr) => prev + curr.value,
      0
    );

    const totalAmountTuition = tuitions.overdues.reduce(
      (prev, curr) => prev + curr.value,
      0
    );

    const totalAmountExpired = expiredPreEnrollment.reduce(
      (prev, curr) => prev + curr.value,
      0
    );
    return totalAmountPreEnrollment + totalAmountTuition - totalAmountExpired;
  }, [enrollments.overdues, expiredPreEnrollment, tuitions.overdues]);

  return {
    nextTuitionDueDateToPay,
    nextPreEnrollmentDueDateToPay,
    overdueAmount,
    expiredPreEnrollment,
    sameNextDueDateEnrollment,
  };
};

export type useGreetingInformationProps = {
  tuitions: SplitInstallments;
  enrollments: SplitInstallments;
};
