import type { ReactNode } from 'react';
import { cn } from '@srcTailwindUi/lib/utils';
import type { Variant } from './types';
import { rootVariants } from './variants';
type BannerRootProps = {
  children: ReactNode;
  variant?: Variant;
};
export function BannerRoot({
  children,
  variant = 'default'
}: BannerRootProps) {
  return <div className={cn(rootVariants({
    variant
  }))} data-sentry-component="BannerRoot" data-sentry-source-file="BannerRoot.tsx">{children}</div>;
}