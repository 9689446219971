import type { ReactNode } from 'react';
import { Button } from '@gravity/button';
import { Spinner } from '@srcTailwindUi/components/Spinner';
import { cn } from '@srcTailwindUi/lib/utils';
import type { Variant } from './types';
import { buttonVariants } from './variants';
type BannerButtonProps = {
  label: ReactNode;
  loading?: boolean;
  variant?: Variant;
  onClick?: () => void;
};
export function BannerButton({
  variant = 'default',
  label,
  loading = false,
  onClick
}: BannerButtonProps) {
  return <Button className={cn(buttonVariants({
    variant
  }), `justify-center ${loading ? 'text-transparent disabled:text-transparent' : ''}`)} onClick={onClick} data-sentry-element="Button" data-sentry-component="BannerButton" data-sentry-source-file="BannerButton.tsx">
      <Spinner variant="secondary" className={`${loading ? 'absolute flex' : 'hidden'}`} data-sentry-element="Spinner" data-sentry-source-file="BannerButton.tsx" />
      {label}
    </Button>;
}