export enum GuardianContractOverviewStatus {
  ALL_PAID = 'ALL_PAID',
  CANCELED = 'CANCELED',
  NONE_PENDING = 'NONE_PENDING',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
}

export type ContractOverview = {
  contract_id: string;
  installments_count: number;
  next_installment_due_date: string;
  next_installment_final_amount: number;
  next_installment_numeration: number;
  product_name: string;
  status: GuardianContractOverviewStatus;
};

export type StudentSchoolMatch = {
  contracts_status: GuardianContractOverviewStatus;
  school_id: string;
  school_name: string;
  student_id: string;
  student_name: string;
};
