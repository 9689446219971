'use client';

import React from 'react';
import type { InsuranceContractsData } from '@monorepo/interfaces';
import { usePostEvents } from '@monorepo/events';
import { useRouter } from '@monorepo/router';
import { UnleashFlags, useUnleashFlag } from '@monorepo/utils/hooks/unleash';
import { CardButton } from '@srcTailwindUi/components/CardButton';
export const InsuranceButton = ({
  insuranceContracts
}: {
  insuranceContracts?: InsuranceContractsData;
}) => {
  const enabledInsurance = useUnleashFlag(UnleashFlags.MIS_267_ENABLE_INSURANCE);
  const router = useRouter();
  const {
    mutate: postEvent
  } = usePostEvents();
  if (!enabledInsurance || !insuranceContracts?.insuredContracts?.length) return null;
  return <CardButton chipText="Novo" chipVariant="green" text="Seguro familiar isaac" onClick={() => {
    postEvent({
      app: 'insurance',
      event: 'home_button',
      action: 'click'
    });
    return router.push('/seguro-familiar');
  }} data-sentry-element="CardButton" data-sentry-component="InsuranceButton" data-sentry-source-file="index.tsx" />;
};