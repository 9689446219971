'use client';

import React from 'react';
import { Text } from '@gravity/text';
import { v4 as uuidv4 } from 'uuid';
import type { InstallmentItemData } from '../types';
import type { InstallmentEntryProps } from './types';
import { InstallmentCardEntry } from './InstallmentCard';
export const SkeletonInstallmentEntry = () => <>
    <div className="flex max-w-[683px] flex-col gap-4 px-6 pb-8 pt-4 sm:px-0">
      {Array.from({
      length: 3
    }).map(() => <InstallmentCardEntry key={uuidv4()} loading />)}
    </div>
  </>;
const InstallmentEntry = ({
  data,
  onClick
}: InstallmentEntryProps) => {
  const {
    title,
    items
  } = data;
  const handleClick = (itemIndex: number) => {
    const item = items[itemIndex];
    if (!item || !onClick) return;
    const data: InstallmentItemData = {
      id: item.id,
      type: item.type,
      receivableType: item.receivableType,
      status: item.status,
      overdue: item.overdue
    };
    onClick(data);
  };
  return <>
      <div className="flex max-w-[683px] flex-col gap-4 px-6 py-0 sm:px-0">
        <Text variant="body-2-regular" className="mx-0 mb-6 mt-8 py-0 leading-5 tracking-[0.25px] text-gray-600" data-sentry-element="Text" data-sentry-source-file="index.tsx">
          {title}
        </Text>
      </div>
      <div className="flex max-w-[683px] flex-col gap-4 px-6 py-0 sm:px-0">
        {items.map((item, index) => <InstallmentCardEntry key={item.id} data={item} onClick={() => handleClick(index)} />)}
      </div>
    </>;
};
export default InstallmentEntry;