'use client';

import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import { diffDays } from '@monorepo/utils/dateHelpers';
import { formatReal } from '@monorepo/utils/numberFormatters';

import type { GreetingStatusGuardian } from '../types';

export const useGreetingMessageTuition = ({
  error = false,
  status,
  tuitionsOverdues,
  enrollmentsOverdues,
  expiredEnrollmentCount,
  totalAmount,
  nextTuitionDueDateToPay,
  nextPreEnrollmentDueDateToPay,
}: useGreetingMessageTuitionProps) => {
  const contOverdue =
    tuitionsOverdues + enrollmentsOverdues - expiredEnrollmentCount;

  const messageOverdue = useCallback(() => {
    return contOverdue === 1
      ? `Você tem 1 parcela vencida, somando ${formatReal(totalAmount)}.`
      : `Você tem ${contOverdue} parcelas vencidas, somando ${formatReal(totalAmount)}.`;
  }, [contOverdue, totalAmount]);

  const messagePreEnrollmentOverdue = useCallback(() => {
    const diffDate = diffDays(nextTuitionDueDateToPay);
    if (diffDate === 0) return 'A próxima mensalidade vence hoje.';
    if (diffDate === 1) return 'A próxima mensalidade vence amanhã.';
    if (diffDate > 0) return `A próxima mensalidade vence em ${diffDate} dias.`;
  }, [nextTuitionDueDateToPay]);

  const getNextDueDate = (date: string, compareDate: string) => {
    if (!date) return compareDate;
    if (!compareDate) return date;

    return dayjs(date).isBefore(compareDate) ? date : compareDate;
  };

  const messageAllOndue = useCallback(() => {
    if (error) return 'Não encontramos faturas.';

    const nextDueDate = getNextDueDate(
      nextTuitionDueDateToPay,
      nextPreEnrollmentDueDateToPay
    );

    const diffDate = diffDays(nextDueDate);
    if (diffDate === 0) return 'Sua próxima parcela vence hoje!';
    if (diffDate === 1) return 'Sua próxima parcela vence amanhã.';
    if (diffDate > 0) return `Sua próxima parcela vence em ${diffDate} dias!`;
    return 'Você não tem parcelas em aberto.';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPreEnrollmentDueDateToPay, nextTuitionDueDateToPay]);

  const message = useMemo(() => {
    if (status.isOverdueTuition) {
      return messageOverdue();
    }

    if (status.isOverduePreEnrollment) {
      return messagePreEnrollmentOverdue();
    }

    return messageAllOndue();
  }, [
    messageAllOndue,
    messageOverdue,
    messagePreEnrollmentOverdue,
    status.isOverduePreEnrollment,
    status.isOverdueTuition,
  ]);

  return { message };
};

export type useGreetingMessageTuitionProps = {
  error?: boolean;
  status: GreetingStatusGuardian;
  tuitionsOverdues: number;
  enrollmentsOverdues: number;
  expiredEnrollmentCount: number;
  totalAmount: number;
  nextTuitionDueDateToPay: string;
  nextPreEnrollmentDueDateToPay: string;
};
