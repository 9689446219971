'use client';

import { MdErrorOutline } from 'react-icons/md';
import { Toaster as Sonner } from 'sonner';
type ToasterProps = React.ComponentProps<typeof Sonner>;
const Toaster = ({
  ...props
}: ToasterProps) => {
  return <Sonner icons={{
    error: <MdErrorOutline className="h-6 w-6" />
  }} className="toaster group" toastOptions={{
    classNames: {
      error: 'group-[.toaster]:bg-red-200 group-[.toaster]:text-red-300',
      success: 'group-[.toaster]:bg-green-200 group-[.toaster]:text-green-700',
      warning: 'group-[.toaster]:bg-isaacBlue-100 group-[.toaster]:text-isaacBlue-600',
      info: 'group-[.toaster]:bg-yellow-200 group-[.toaster]:text-yellow-700',
      toast: 'group toast group-[.toaster]:gap-4 group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
      title: 'font-roboto text-base font-normal',
      description: 'group-[.toast]:text-muted-foreground',
      actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
      cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground'
    }
  }} {...props} data-sentry-element="Sonner" data-sentry-component="Toaster" data-sentry-source-file="Sonner.tsx" />;
};
export { Toaster, type ToasterProps };