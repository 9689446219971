'use client';

import { Text } from '@gravity/text';
import { CircleCheck, Info, TriangleAlert } from 'lucide-react';
import type { InstallmentStatus } from '@monorepo/interfaces';
import { Button } from '@srcTailwindUi/components/Button';
export type NoInstallmentsProps = {
  state?: InstallmentStatus;
  error?: boolean;
};
const NoInstallments = ({
  state,
  error = false
}: NoInstallmentsProps) => {
  if (error) {
    return <div className="flex flex-col items-center justify-center gap-6 px-6 py-10" data-testid="no-installments">
        <div className="flex flex-col items-center justify-center gap-2">
          <TriangleAlert className="h-6 w-6 text-coral-900" data-testid="warning-icon" />
          <Text variant="body-2-medium" className="text-center text-gray-800">
            Não encontramos suas faturas.
          </Text>

          <Text variant="body-2-regular" className="text-center text-gray-600">
            Estamos trabalhando para resolver essa instabilidade na plataforma,
            tente novamente mais tarde.
          </Text>
        </div>
        <Button onClick={() => {
        window.location.reload();
      }}>
          Atualizar página
        </Button>
      </div>;
  }
  const message = state === 'OPEN' ? 'Aguardando pagamento' : 'Você ainda não pagou nenhuma parcela';
  return <div className="flex flex-col items-center justify-center gap-4 px-6 py-10" data-testid="no-installments" data-sentry-component="NoInstallments" data-sentry-source-file="index.tsx">
      {state == 'PAID' ? <Info className="h-6 w-6 text-blue-600" data-testid="info-icon" /> : <CircleCheck className="h-6 w-6 text-blue-600" data-testid="check-icon" />}
      <Text variant="body-2-regular" className="text-center text-gray-400" data-sentry-element="Text" data-sentry-source-file="index.tsx">
        {message}
      </Text>
    </div>;
};
export default NoInstallments;