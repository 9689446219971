import type { VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '@srcTailwindUi/lib/utils';
const badgeVariants = cva('inline-flex items-center rounded-full border px-2.5 py-0.5 font-roboto text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2', {
  variants: {
    variant: {
      green: 'border-transparent bg-green-100 text-green-900',
      coral: 'border-transparent bg-coral-200 text-coral-900',
      gray: 'border-transparent bg-gray-50 text-gray-800',
      yellow: 'border-transparent bg-yellow-100 text-yellow-900'
    }
  },
  defaultVariants: {
    variant: 'green'
  }
});
export type BadgeProps = {} & React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof badgeVariants>;
function Badge({
  className,
  variant,
  ...props
}: BadgeProps) {
  return <div className={cn(badgeVariants({
    variant
  }), className)} {...props} data-sentry-component="Badge" data-sentry-source-file="index.tsx" />;
}
export { Badge, badgeVariants };