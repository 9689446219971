'use client';

import React from 'react';
import type { HasContractsToApproveData } from '@monorepo/interfaces';
import { usePostEvents } from '@monorepo/events';
import { SessionStorageKeys } from '@monorepo/interfaces';
import { useRouter } from '@monorepo/router';
import { UnleashFlags, useUnleashFlag } from '@monorepo/utils/hooks/unleash';
import { CardButton } from '@srcTailwindUi/components/CardButton';
import ConfirmationDataDialog from './Dialog';
export const FidcConfirmationButton: React.FC<{
  fidcData?: HasContractsToApproveData;
}> = ({
  fidcData
}) => {
  const router = useRouter();
  const enabledFidc = useUnleashFlag(UnleashFlags.ONB_201_ENABLE_FIDC);
  const is2024ContractsEnabled = useUnleashFlag(UnleashFlags.MIS_211_FIDC_2024);
  const {
    mutate: postEvent
  } = usePostEvents();
  const [openDialog, setOpenDialog] = React.useState(false);
  React.useEffect(() => {
    setOpenDialog(sessionStorage.getItem(SessionStorageKeys.VIEWED_FIDC_MODAL) !== 'true');
  }, []);
  const isContested = fidcData?.details?.contested?.length && !fidcData?.details?.waiting_for_approval?.length;
  const chipText = isContested ? 'Contestado' : 'Pendente';
  const chipVariant = isContested ? 'yellow' : 'coral';
  if (!enabledFidc || !fidcData?.hasContractsToApprove) return null;
  return <>
      <CardButton chipText={chipText} chipVariant={chipVariant} text={is2024ContractsEnabled ? 'Revisão de contrato 2024' : 'Revisão de contrato'} onClick={() => {
      postEvent({
        app: 'fidc-confirmation',
        action: 'click',
        event: 'click_FIDC_start'
      });
      return router.push('/revisao/identidade');
    }} data-sentry-element="CardButton" data-sentry-source-file="index.tsx" />
      <ConfirmationDataDialog open={openDialog} onClose={() => {
      setOpenDialog(false);
      sessionStorage.setItem(SessionStorageKeys.VIEWED_FIDC_MODAL, 'true');
    }} fidcData={fidcData} data-sentry-element="ConfirmationDataDialog" data-sentry-source-file="index.tsx" />
    </>;
};