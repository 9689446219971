import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import 'dayjs/locale/pt-br';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.locale('pt-br');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

type DateType = Dayjs | string | undefined | null;

export const makeIsDayAfterOrSameDate =
  (baseDate: DateType) =>
  (currentComparedDate: DateType): boolean => {
    if (!currentComparedDate || !baseDate) return false;
    return (
      dayjs(currentComparedDate).isSame(baseDate, 'day') ||
      dayjs(currentComparedDate).isAfter(baseDate, 'day')
    );
  };

export const diffDays = (date: DateType) => {
  const dateInTheEndOfDay = dayjs(date).endOf('day');
  return dayjs(dateInTheEndOfDay).diff(dayjs(), 'day');
};

export const extractMonth = (date: DateType) => dayjs(date).month() + 1;

export const extractYear = (date: DateType) => dayjs(date).get('year');

export const subtractOneDay = (date: DateType) =>
  dayjs(date).subtract(1, 'day').toISOString();

export const subtractDays = (date: DateType, days: number) =>
  dayjs(date).subtract(days, 'day').toISOString();

export const endOfDay = (date: DateType) =>
  dayjs(date).endOf('day').toISOString();

export const fullEndOfDay = (date: DateType) =>
  dayjs(date).format('YYYY-MM-DD').concat('T23:59:59Z');

export const removeTime = (date: string | undefined) => {
  return date ? date.split('T')[0] : undefined;
};

export const pickTime = (date: string | undefined) => {
  return date ? date.split('T')[1] : undefined;
};

// NOTE: Método que garante a correta visualização do due_date até que seja feita a adequação na API de negociação
export const sanitizeDate = (date: string | undefined) => {
  if (!date) return undefined;
  const time = String(pickTime(date));
  const knownTimeForms = ['00:00:00', '23:59:59', '02:59:59', '03:00:00'];

  if (!time && !knownTimeForms.includes(time)) {
    console.error(
      `Unknown due_date time format found running sanitizeDate method: ${time}`
    );
  }

  const rejectedTimeForms = ['02:59:59', '03:00:00'];
  const adjusted = rejectedTimeForms.some((a) => time?.startsWith(a))
    ? subtractOneDay(date)
    : date;
  return removeTime(adjusted);
};

export const isDayTodayOrAfter = makeIsDayAfterOrSameDate(dayjs());

export const compareDates = (compared: DateType, base: DateType = dayjs()) =>
  dayjs(compared).isBefore(base);

export const getMonthName = (monthNumber: number) => {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  return months[monthNumber];
};

export const setZeroedTime = (date: string | undefined) => {
  if (!date) return undefined;
  return dayjs(date.split('T')[0]).format('YYYY-MM-DDT00:00:00').concat('Z');
};

export const getDefaultDate = (currentDate: Dayjs) => {
  const defaultDate = currentDate.add(1, 'day');

  switch (defaultDate.day()) {
    case 0:
      return currentDate.add(2, 'day').format('YYYY-MM-DD');
    case 6:
      return currentDate.add(3, 'day').format('YYYY-MM-DD');
    default:
      return defaultDate.format('YYYY-MM-DD');
  }
};

export const getDefaultLastDate = (currentDate: Dayjs) => {
  const defaultDate = currentDate.add(7, 'day');

  switch (defaultDate.day()) {
    case 0:
      return currentDate.add(5, 'day').format('YYYY-MM-DD');
    case 6:
      return currentDate.add(6, 'day').format('YYYY-MM-DD');
    default:
      return defaultDate.format('YYYY-MM-DD');
  }
};

export const getDateOptions = (currentDate: Dayjs) => {
  const dateOptions: dayjs.Dayjs[] = [];

  for (let i = 1; i < 8; i++) {
    const nextDate = currentDate.tz('America/Sao_Paulo').add(i, 'day');
    if (nextDate.day() !== 0 && nextDate.day() !== 6) {
      dateOptions.push(nextDate);
    }
  }

  return dateOptions.map((date) => ({
    value: date.format('YYYY-MM-DD'),
    label: date.format(`DD [de] MMMM`),
  }));
};
