import type { ReactNode } from 'react';
type BannerFooterProps = {
  children: ReactNode;
};
export function BannerFooter({
  children
}: BannerFooterProps) {
  return <div className="flex w-full self-baseline px-3 pb-4 sm:w-full sm:flex-row sm:items-center sm:justify-start" data-sentry-component="BannerFooter" data-sentry-source-file="BannerFooter.tsx">
      {children}
    </div>;
}