'use client';

import React from 'react';
import { BannerCard } from '@srcTailwindUi/components/BannerCard';
import { useDefaultersBanner } from './useDefaultersBanner';
const DefaultersBanner: React.FC = () => {
  const {
    title,
    subtitle,
    button,
    src,
    subtitleMarginTop,
    onClickBanner
  } = useDefaultersBanner();
  return <BannerCard title={<strong>{title}</strong>} subtitle={subtitle} buttonText={button} variant="lightGreen" fontSizeDesktop="small" smallImage src={src} subtitleMarginTop={subtitleMarginTop} contentWidth="100%" onClick={onClickBanner} data-sentry-element="BannerCard" data-sentry-component="DefaultersBanner" data-sentry-source-file="index.tsx" />;
};
export default DefaultersBanner;