export enum UnleashFlags {
  ONB_201_ENABLE_FIDC = 'ONB_201_ENABLE_FIDC',
  MIS_72_ENABLE_MATRICULAS_ON_MEU_ISAAC = 'MIS_72_ENABLE_MATRICULAS_ON_MEU_ISAAC',
  MAT_57_ENABLE_PRE_MATRICULA = 'MAT_57_ENABLE_PRE_MATRICULA',
  MIS_267_ENABLE_INSURANCE = 'MIS_267_ENABLE_INSURANCE',
  B2CB_696_ENABLE_MEU_ISAAC_MAINTENANCE_MODE = 'B2CB_696_ENABLE_MEU_ISAAC_MAINTENANCE_MODE',
  B2CB_814_REDIRECT_TO_LANDING_PAGE = 'B2CB_814_REDIRECT_TO_LANDING_PAGE',
  ONB_315_FIDC_BUTTON_AB = 'ONB_315_FIDC_BUTTON_AB',
  B2CB_850_ENABLE_DISCOUNT_CAMPAIGN = 'B2CB_850_ENABLE_DISCOUNT_CAMPAIGN',
  MIS_211_FIDC_2024 = 'MIS_211_FIDC_2024',
  MIS_277_REQUIRED_FIDC_CONFIRMATION = 'MIS_277_REQUIRED_FIDC_CONFIRMATION',
  REG_2086_ENABLE_CREDIT_CARD_PAYMENT = 'REG_2086_ENABLE_CREDIT_CARD_PAYMENT',
}
