import { z } from 'zod';

export const booleanParser = z
  .string()
  .optional()
  .refine(
    (s) => s === 'true' || s === 'false' || s === undefined,
    (s) => ({
      message: `BooleanParser expects a boolean string or undefined, but you passed ${s}`,
    })
  )
  .transform((s) => s === 'true');
