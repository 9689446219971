import type { JWTPayload } from 'jose';

import type { FidcStatusType, ReviewedStatusType } from './status';

export enum FidcCookiesKeys {
  USER_CONFIRMATION_DATA = '__OISA-FC-UD',
  USER_CONTRACTS = '__OISA-FC-UC',
}

export type UserContactData = {
  phone: string;
  email: string;
  status: FidcStatusType;
};

export type UserAddressData = {
  zipCode: string;
  street: string;
  streetNumber: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  status: FidcStatusType;
};

export type UserConfirmationInputData = {
  contact?: UserContactData;
  address?: UserAddressData;
  contracts?: Record<string, ReviewedStatusType>;
  nextUrl?: string;
  allowedPathnames?: string[];
  validated?: boolean;
  hasPreContract?: boolean;
};

export type UserConfirmationData = UserConfirmationInputData & JWTPayload;

export type UserContractsIds = {
  contractsIds?: string[];
} & JWTPayload;
