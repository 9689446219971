import { cva } from 'class-variance-authority';

export const rootVariants = cva(
  'relative flex h-[196px] w-full animate-bannerCard flex-col overflow-hidden rounded-2xl shadow-banner sm:h-36 sm:justify-between',
  {
    variants: {
      variant: {
        default: 'bg-navy-900',
        lightYellow: 'bg-yellow-200',
        lightBlue: 'bg-blue-200',
        hawkesBlue: 'bg-navy-900',
        yellow: 'bg-yellow-200',
        lightGreen: 'bg-green-200',
        gray: 'text-gray-800',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export const titleVariants = cva(
  'w-full text-start font-normal not-italic tracking-[0.15px]',
  {
    variants: {
      variant: {
        default: 'text-yellow-400',
        lightYellow: 'text-blue-600',
        lightBlue: 'text-blue-600',
        hawkesBlue: 'text-[#D1DBFF]',
        yellow: 'text-blue-600',
        lightGreen: 'text-blue-600 sm:leading-6',
        gray: 'text-gray-800',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export const secondTitleVariants = cva(
  'w-full text-start font-medium not-italic tracking-[0.15px]',
  {
    variants: {
      variant: {
        default: 'text-yellow-400',
        lightYellow: 'text-blue-600',
        lightBlue: 'text-blue-600',
        yellow: 'text-blue-600',
        lightGreen: 'text-blue-600',
        gray: 'text-gray-800',
      },
      size: {
        small: 'text-sm sm:text-xl sm:leading-9',
        default: 'text-base sm:text-2xl sm:leading-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export const subtitleVariant = cva(
  'text-start font-normal not-italic tracking-[0.3px] sm:mt-2',
  {
    variants: {
      variant: {
        default: 'text-white',
        lightYellow: 'text-gray-80',
        lightBlue: 'text-blue-600',
        hawkesBlue: 'text-white',
        yellow: 'text-gray-900',
        gray: 'text-gray-800',
        lightGreen:
          'mb-3 w-[186px] tracking-[0.4px] text-gray-800 sm:w-[358px]',
      },
      size: {
        small: 'text-[12px] leading-[18px] sm:text-sm sm:leading-5',
        default: 'text-sm leading-[18px] sm:text-base sm:leading-6',
      },
      fontSizes: {
        small: 'sm:text-sm sm:leading-5',
        default: 'sm:text-base sm:leading-6',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export const buttonVariants = cva(
  'h-8 w-full min-w-40 rounded-lg p-3 text-center text-sm font-medium normal-case not-italic leading-5 tracking-[1px] transition-bg duration-100 hover:cursor-pointer sm:mt-3 sm:w-auto',
  {
    variants: {
      variant: {
        default: 'bg-yellow-400 text-navy-900 hover:bg-yellow-400',
        lightYellow: 'bg-blue-600 text-white hover:bg-blue-600',
        lightBlue: 'bg-blue-600 text-white hover:bg-blue-600',
        hawkesBlue: 'bg-[#D1DBFF] text-navy-900 hover:bg-[#D1DBFF]',
        yellow: 'bg-blue-600 text-white hover:bg-blue-600',
        lightGreen: 'bg-blue-600 text-white hover:bg-blue-600',
        gray: 'text-gray-800',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);
