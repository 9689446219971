'use client';

import * as React from 'react';
import { X } from 'lucide-react';
import { useWindowSize } from '@monorepo/utils/hooks/useWindowSize';
import { Dialog, DialogContent, DialogHeader } from '@srcTailwindUi/components/Dialog';
import { Drawer, DrawerContent } from '@srcTailwindUi/components/Drawer';
import { cn } from '@srcTailwindUi/lib/utils';
export type ResponsiveDialogProps = {
  open: boolean;
  onClose(): void;
  dragIndicator?: boolean;
  allowClosing?: boolean;
  absoluteCloseButton?: boolean;
  showCloseButton?: boolean;
  removeAllCloseButtons?: boolean;
} & ({
  headerComponent?: undefined;
  dragIndicatorBgColor?: undefined;
} | {
  headerComponent: JSX.Element;
  dragIndicatorBgColor: string;
});
export function ResponsiveDialog({
  open,
  onClose,
  headerComponent,
  dragIndicatorBgColor,
  allowClosing = true,
  absoluteCloseButton,
  dragIndicator = true,
  removeAllCloseButtons,
  showCloseButton = true,
  children
}: React.PropsWithChildren<ResponsiveDialogProps>) {
  const {
    width
  } = useWindowSize();
  const isDesktop = (width ?? 0) > 635;
  const renderCloseButton = !removeAllCloseButtons && showCloseButton && allowClosing;
  if (!width) return null;
  if (isDesktop) {
    return <Dialog open={open} onOpenChange={value => {
      if (value === false && allowClosing) onClose();
    }}>
        <DialogContent>
          {headerComponent}
          {renderCloseButton ? <DialogHeader className={cn('z-[1] flex justify-end pb-3', absoluteCloseButton ? 'absolute right-6 top-6' : '')}>
              <X className="h-5 w-5 cursor-pointer text-gray-600" onClick={allowClosing ? onClose : undefined} />
            </DialogHeader> : null}
          <div className="flex-1 p-6">{children}</div>
        </DialogContent>
      </Dialog>;
  }
  return <Drawer open={open} onOpenChange={value => {
    if (value === false && allowClosing) onClose();
  }} dismissible={allowClosing} data-sentry-element="Drawer" data-sentry-component="ResponsiveDialog" data-sentry-source-file="index.tsx">
      <DrawerContent dragIndicatorBgColor={dragIndicatorBgColor} showDragIndicator={dragIndicator && renderCloseButton} data-sentry-element="DrawerContent" data-sentry-source-file="index.tsx">
        {headerComponent}
        <div className="flex-1 p-6">{children}</div>
      </DrawerContent>
    </Drawer>;
}