'use client';

import * as React from 'react';
import Link from 'next/link';
import { Text } from '@gravity/text';
import { mdiArrowLeft, mdiClose, mdiDotsVertical } from '@mdi/js';
import Icon from '@mdi/react';
import { SheetClose } from '@srcTailwindUi/components/Sheet';
import { cn } from '@srcTailwindUi/lib/utils';
type AppBarProps = {
  title?: string;
  href?: string;
  onClick?: () => void;
  doClose?: () => void;
  noButton?: boolean;
  verticalIcon?: boolean;
  className?: string;
  insideSheet?: boolean;
  singleViewMode?: boolean;
};
const View = ({
  title,
  href,
  onClick,
  verticalIcon,
  doClose,
  noButton,
  className,
  insideSheet,
  singleViewMode = false
}: AppBarProps) => {
  const Comp = React.useMemo(() => {
    if (href) return Link;
    if (insideSheet) return SheetClose;
    return 'button';
  }, [href, insideSheet]);
  return <div className={cn(singleViewMode ? 'fixed left-0 top-0 z-[1000] flex h-14 w-full items-start justify-center bg-white px-6 py-4 md:fixed md:left-auto md:right-0 md:top-0' : 'fixed top-0 flex h-14 w-full items-start justify-center bg-white px-6 py-4 md:static md:h-auto md:w-auto md:self-start md:px-0 md:py-0', className)} data-sentry-component="View" data-sentry-source-file="view.tsx">
      <div className="grid h-6 w-full max-w-screen-sm grid-cols-3 items-center gap-4">
        {noButton ? <div /> : <Comp href={href ?? ''} onClick={onClick} className={cn('flex cursor-pointer justify-start gap-2 text-gray-800 transition-colors', !singleViewMode && 'md:items-center md:text-blue-600 md:hover:text-blue-400 md:focus:outline md:focus:outline-2 md:focus:outline-offset-2 md:focus:outline-blue-300', insideSheet ? 'px-0' : '')}>
            <div className="flex items-center gap-1.5" data-testid="app-bar-back-button">
              <Icon path={mdiArrowLeft} size="20px" />
              {!singleViewMode && <Text variant="body-2-medium" className="hidden md:block md:text-blue-600 md:hover:text-blue-400">
                  Voltar
                </Text>}
            </div>
          </Comp>}
        <div className={cn(singleViewMode ? 'flex justify-center' : 'flex justify-center md:hidden')}>
          <Text variant="body-2-medium" className={`whitespace-nowrap text-center ${singleViewMode ? 'text-gray-600' : 'text-gray-800'}`} data-sentry-element="Text" data-sentry-source-file="view.tsx">
            {title}
          </Text>
        </div>
        {singleViewMode && (verticalIcon ? <div className="flex justify-end text-gray-800">
              <Icon path={mdiDotsVertical} size="20px" />
            </div> : doClose ? <div className="flex justify-end text-gray-800">
              <button style={{
          all: 'unset'
        }} data-testid="close-button" onClick={doClose}>
                <Icon path={mdiClose} size="20px" />
              </button>
            </div> : null)}
      </div>
    </div>;
};
export { View, type AppBarProps };