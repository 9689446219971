'use client';

import React from 'react';
import { Button } from '@gravity/button';
import { Heading } from '@gravity/heading';
import type { HasContractsToApproveData } from '@monorepo/interfaces';
import { usePostEvents } from '@monorepo/events';
import { useRouter } from '@monorepo/router';
import { toTitleCase } from '@monorepo/utils/toTitleCase';
import { ResponsiveDialog } from '@srcTailwindUi/components/ResponsiveDialog';
import DialogImage from './image.svg';
type Props = {
  open: boolean;
  onClose(): void;
  fidcData?: HasContractsToApproveData;
};
const listItems = ['Valide sua identidade;', 'Confirme seus dados de contato;', 'Revise o contrato 2024 enviado pela escola'];
const Dialog2024 = ({
  open,
  onClose,
  fidcData
}: Props) => {
  const router = useRouter();
  const {
    mutate: postEvent,
    isReady
  } = usePostEvents();
  React.useEffect(() => {
    if (open && isReady) postEvent({
      app: 'fidc-confirmation',
      action: 'page_view',
      event: 'page_view_FIDC_start',
      experiment: {
        version: '2024'
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, isReady]);
  return <ResponsiveDialog open={open} onClose={() => {
    onClose();
    postEvent({
      app: 'fidc-confirmation',
      action: 'click',
      event: 'dismiss_modal_FIDC',
      experiment: {
        version: '2024'
      }
    });
  }} allowClosing absoluteCloseButton dragIndicatorBgColor="bg-blue-100" headerComponent={<div className={`flex h-40 items-center justify-center bg-blue-100 bg-contain bg-center bg-no-repeat bg-origin-content p-3`} style={{
    backgroundImage: `url('${DialogImage.src}')`
  }} />} data-sentry-element="ResponsiveDialog" data-sentry-component="Dialog2024" data-sentry-source-file="index.tsx">
      <div className="flex w-full flex-col gap-8 sm:gap-6">
        <div className="flex w-full flex-col gap-6">
          <div className="flex w-full flex-col gap-2">
            <Heading variant="heading-h3-medium" className="flex flex-col text-gray-800 sm:flex-row sm:text-2xl sm:leading-9" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
              {fidcData?.schools?.length === 1 ? `Revise seu contrato 2024 com ${toTitleCase(fidcData.schools[0]?.name)} e deixe sua conta mais segura!` : 'Revise seu contrato 2024 e deixe sua conta mais segura!'}
            </Heading>
          </div>
          <div className="flex flex-col">
            <ul className="m-0 flex w-full list-none flex-col gap-6 overflow-hidden p-0 ps-0 [counter-reset:item]">
              {listItems.map(item => {
              return <li key={item} className="flex items-baseline pl-4 text-base font-normal tracking-[0.5px] text-gray-600 [counter-increment:item] before:mr-3 before:inline-block before:h-5 before:min-w-5 before:rounded-full before:bg-gray-30 before:text-center before:text-sm before:font-normal before:tracking-[0.25px] before:text-gray-800 before:content-[counter(item)]">
                    {item}
                  </li>;
            })}
            </ul>
          </div>
        </div>
        <div className="mt-2 hidden w-[635px] -translate-x-6 border-b border-solid border-gray-100 sm:flex" />
        <div className="flex w-full flex-row gap-2 sm:justify-end">
          <Button className="sm:w-fit sm:p-3" fullWidth onClick={() => {
          onClose();
          postEvent({
            app: 'fidc-confirmation',
            action: 'click',
            event: 'continue_modal_FIDC',
            experiment: {
              version: '2024'
            }
          });
          return router.push('/revisao/identidade');
        }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            Revisar agora
          </Button>
        </div>
      </div>
    </ResponsiveDialog>;
};
export default Dialog2024;