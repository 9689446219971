'use client';

import { Heading } from '@gravity/heading';
import { Text } from '@gravity/text';
import type { GetInstallmentV2RouteResponse } from '@monorepo/interfaces';
import { Skeleton } from '@srcTailwindUi/components/Skeleton';
import { useNegotiationMessages } from './useNegotiationMessages';
export const NegotiationMessages = ({
  installmentsData,
  isLoading,
  isError
}: {
  installmentsData?: GetInstallmentV2RouteResponse;
  isLoading: boolean;
  isError: boolean;
}) => {
  const {
    messages
  } = useNegotiationMessages({
    installmentsData,
    isError
  });
  if (isLoading) return <div className="flex min-h-[62px] flex-col justify-around px-6 sm:p-0">
        <Skeleton className="h-4 w-full bg-gray-40 bg-opacity-40" />
        <Skeleton className="h-4 w-1/2 bg-gray-40 bg-opacity-40" />
      </div>;
  return <div className="flex flex-col gap-2 px-6 sm:p-0" data-sentry-component="NegotiationMessages" data-sentry-source-file="index.tsx">
      <Heading variant="heading-h3-regular" className="text-white sm:text-[1.5rem]" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
        {messages.firstMessage}
      </Heading>
      <Text variant="subtitle-regular" className="text-white sm:tracking-[0.5px]" data-sentry-element="Text" data-sentry-source-file="index.tsx">
        {messages.secondMessage}
      </Text>
    </div>;
};