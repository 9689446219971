'use client';

import type { Control, FieldValues, Path } from 'react-hook-form';
import type { ControlledFormItemProps } from '@srcTailwindUi/components/ControlledFormItem';
import { ControlledFormItem } from '@srcTailwindUi/components/ControlledFormItem';
import { FormField } from '@srcTailwindUi/components/Form';
type ControlledFormFieldProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  control: Control<TFieldValues> | undefined;
} & Omit<ControlledFormItemProps<TFieldValues>, 'field'>;
const ControlledFormField = <TFieldValues extends FieldValues,>({
  name,
  label,
  control,
  description,
  ...props
}: ControlledFormFieldProps<TFieldValues>) => {
  return <FormField name={name} control={control} render={({
    field
  }) => {
    return <ControlledFormItem field={field} label={label} description={description} {...props} />;
  }} data-sentry-element="FormField" data-sentry-component="ControlledFormField" data-sentry-source-file="index.tsx" />;
};
export { ControlledFormField, type ControlledFormFieldProps };