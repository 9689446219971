'use client';

import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import type { GetInstallmentV2RouteResponse, HasContractsToApproveData } from '@monorepo/interfaces';
import type { NegotiationData } from '@monorepo/interfaces/redis';
import { useSendEvents } from '@monorepo/events/src/negotiation';
import { EventScope, NegotiationEvents } from '@monorepo/events/src/types';
import { SessionStorageKeys } from '@monorepo/interfaces';
import { useRouter } from '@monorepo/router';
import { UnleashFlags, useUnleashFlag } from '@monorepo/utils/hooks/unleash';
import { replaceLastComma } from '@monorepo/utils/stringHelpers';
const MIN_DATE = dayjs('2024-08-27');
export const useNegotiationDialog = ({
  mutateUpdateNegotiationData,
  negotiationData,
  installmentsData,
  fidcData
}: {
  mutateUpdateNegotiationData: (variables: NegotiationData) => void;
  negotiationData?: NegotiationData;
  installmentsData?: GetInstallmentV2RouteResponse;
  fidcData?: HasContractsToApproveData;
}) => {
  const {
    sendButtonClickEvent
  } = useSendEvents(EventScope.HomePage);
  const router = useRouter();
  const enabledFidc = useUnleashFlag(UnleashFlags.ONB_201_ENABLE_FIDC);
  const isFidcDialogOpen = useMemo(() => enabledFidc && fidcData?.hasContractsToApprove && sessionStorage.getItem(SessionStorageKeys.VIEWED_FIDC_MODAL) !== 'true', [enabledFidc, fidcData]);
  const filteredExpiredDownPayment = useMemo(() => installmentsData?.data?.filter(installment => installment.type === 'DOWN_PAYMENT' && installment.invoice.is_expired && dayjs(installment.due_date).isAfter(MIN_DATE)), [installmentsData?.data]);
  const {
    installmentIds,
    negotiationIds,
    negotiationsSize,
    newExpiredDownPayment
  } = useMemo(() => {
    const newExpiredDownPayment = filteredExpiredDownPayment?.filter(installment => {
      const isNotShowed = !negotiationData?.downPaymentsBrokenPromise?.map(({
        id
      }) => id).includes(installment.id);
      return isNotShowed;
    });
    const negotiationsSize = newExpiredDownPayment ? newExpiredDownPayment.length : 0;
    const [ids, shortIds] = newExpiredDownPayment ? newExpiredDownPayment.reduce(([idsAcc, shortIdsAcc], installment) => {
      const shortId = `#${installment.origin_negotiation?.short_id.toUpperCase()}`;
      return [[...idsAcc, installment.id], [...shortIdsAcc, shortId]];
    }, [[], []] as [string[], string[]]) : [[], []];
    return {
      installmentIds: ids,
      negotiationIds: shortIds,
      negotiationsSize,
      newExpiredDownPayment
    };
  }, [filteredExpiredDownPayment, negotiationData?.downPaymentsBrokenPromise]);
  const [negotiationAlertOpen, setNegotiationAlertOpen] = useState(false);
  const [isLoadingGoToNegotiation, setIsLoadingGoToNegotiation] = useState(false);
  const handlePostEvent = (buttonChoosed: 'ignore' | 'negotiate' = 'ignore') => {
    sendButtonClickEvent({
      event: NegotiationEvents.BROKEN_PROMISE_DOWN_PAYMENT_MODAL,
      data: {
        installments_ids: installmentIds,
        type: buttonChoosed,
        name: buttonChoosed === 'ignore' ? 'Ignorar' : 'Negociar'
      }
    });
  };
  const updateNegotiationData = () => {
    const updatedNegotiationData = newExpiredDownPayment?.map(installment => ({
      id: installment.id,
      shortId: installment.origin_negotiation?.short_id ?? '',
      dateShowed: new Date().toISOString()
    })) ?? [];
    mutateUpdateNegotiationData({
      downPaymentsBrokenPromise: updatedNegotiationData
    });
  };
  const negotiationAlertClose = () => {
    handlePostEvent();
    updateNegotiationData();
    setNegotiationAlertOpen(false);
  };
  const goToNegotiation = () => {
    setIsLoadingGoToNegotiation(true);
    handlePostEvent('negotiate');
    updateNegotiationData();
    router.push('/negociacao/parcelas-vencidas', {
      useWindowOpen: true
    });
  };
  const title = negotiationsSize > 1 ? 'Negociações canceladas' : 'Negociação cancelada';
  const firstSectionDescription = negotiationsSize > 1 ? <>
        Não identificamos o pagamento das parcelas de Entrada das Negociações{' '}
        <strong>{replaceLastComma(negotiationIds.join(', '), ' e')}</strong>.
        Portanto, essas negociações foram desfeitas e as parcelas originais
        voltaram a acumular encargos.
      </> : <>
        Não identificamos o pagamento da parcela de Entrada da Negociação{' '}
        <strong>{negotiationIds[0]}</strong>. Portanto, essa negociação foi
        desfeita e as parcelas originais voltaram a acumular encargos.
      </>;
  const secondSectionDescription = negotiationsSize > 1 ? 'Você pode refazer estas negociações com valores de encargos atualizados e novas condições de pagamento.' : 'Você pode refazer esta negociação com valores de encargos atualizados e novas condições de pagamento.';
  const buttonNegotiateTitle = negotiationsSize > 1 ? 'Refazer negociações' : 'Refazer negociação';
  const buttonIgnoreTitle = 'Ignorar';
  useEffect(() => {
    if (!isFidcDialogOpen && negotiationsSize > 0) setNegotiationAlertOpen(true);
  }, [isFidcDialogOpen, negotiationsSize]);
  return {
    title,
    firstSectionDescription,
    secondSectionDescription,
    buttonNegotiateTitle,
    buttonIgnoreTitle,
    negotiationAlertOpen,
    negotiationAlertClose,
    installmentIds,
    negotiationIds,
    goToNegotiation,
    isLoadingGoToNegotiation
  };
};