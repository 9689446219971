'use client';

import React from 'react';
import type { GetPreEnrollmentStatus, GetStudentsInEnrollment } from '@monorepo/interfaces';
import { usePostEvents } from '@monorepo/events';
import { PreEnrollmentCampaignStatus } from '@monorepo/interfaces/preEnrollment/status';
import { useRouter } from '@monorepo/router';
import { CardButton } from '@srcTailwindUi/components/CardButton';
export const EnrollmentsButton = ({
  studentsInEnrollment,
  preEnrollmentStatus
}: {
  studentsInEnrollment?: GetStudentsInEnrollment;
  preEnrollmentStatus?: GetPreEnrollmentStatus;
}) => {
  const router = useRouter();
  const {
    mutate: postEvent
  } = usePostEvents();
  const hasCampaign = preEnrollmentStatus?.status ? [PreEnrollmentCampaignStatus.ACTIVE_WITH_PRE_ENROLLMENT, PreEnrollmentCampaignStatus.ACTIVE_WITH_ACCEPTED_PROPOSALS, PreEnrollmentCampaignStatus.ACTIVE_WITHOUT_PRE_ENROLLMENT].includes(preEnrollmentStatus?.status) : false;
  const href = hasCampaign ? '/pre-matricula/tenho-interesse' : studentsInEnrollment?.length === 1 ? `/matricula/aluno/${studentsInEnrollment[0]?.studentId}` : '/pre-matricula/contratos';
  if (studentsInEnrollment?.length === 0 && !hasCampaign) return null;
  return <CardButton chipText="Novo!" chipVariant="green" text="Matrícula 2025" onClick={() => {
    if (!hasCampaign) {
      postEvent({
        app: 'matricula-app',
        event: 'home_button',
        action: 'click'
      });
    }
    postEvent({
      app: 'pre-matricula',
      event: 'Button_Clicked',
      scope: 'Matrícula',
      action: 'click',
      data: {
        button_name: 'MI - Matrícula 2025'
      }
    });
    return router.push(href, {
      useWindowOpen: true
    });
  }} data-sentry-element="CardButton" data-sentry-component="EnrollmentsButton" data-sentry-source-file="index.tsx" />;
};