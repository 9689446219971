export type ErrorResponse = {
  field: string;
  message: string;
  tag: string;
};

export type Pagination = Partial<{
  exclude: string;
  page: number;
  per_page: number;
  sort_by: string;
  sort_order: string;
  total: number;
}>;

export type APIResponse<T = unknown> = {
  data: T;
  message?: string;
  errors?: ErrorResponse[];
  pagination?: Pagination;
};

export type RequestPresignedURL = {
  extension: string;
};

export type PresignedURL = {
  file_id: string;
  url: string;
};

export type PathParams = {
  contractId: string;
  guardianId?: string;
  payoutId: string;
  schoolSlug: string;
};

export enum RESPONSE_STATUS {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}
