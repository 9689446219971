'use client';

import type { ImageProps } from 'next/image';
import Image from 'next/image';
import { Button } from '@srcTailwindUi/components/Button';
import { cn } from '@srcTailwindUi/lib/utils';
import type { FontSize, Variant } from './types';
import { buttonVariants, messageVariants, rootVariants, secondTitleVariants, subtitleVariant, titleVariants } from './variants';
export type BannerCardProps = {
  title: React.ReactNode;
  secondTitle?: React.ReactNode;
  subtitle: string;
  message?: string;
  buttonText: string;
  onClick: () => void;
  fontSize?: FontSize;
  fontSizeDesktop?: FontSize;
  smallImage?: boolean;
  src?: ImageProps['src'];
  variant?: Variant;
  nowrap?: boolean;
  contentWidth?: string;
  subtitleMarginTop?: string;
};
export const BannerCard: React.FC<BannerCardProps> = ({
  title,
  secondTitle,
  subtitle,
  message,
  buttonText,
  onClick,
  src,
  smallImage = false,
  fontSize = 'default',
  fontSizeDesktop = 'default',
  nowrap = false,
  variant = 'default',
  contentWidth,
  subtitleMarginTop
}) => {
  return <button onClick={onClick} className={cn(rootVariants({
    variant
  }))} data-sentry-component="BannerCard" data-sentry-source-file="index.tsx">
      <div className={cn('flex h-full flex-col items-center justify-between p-4 pr-0 sm:py-4 sm:pl-6', contentWidth ? `max-w-[${contentWidth}]` : 'max-w-[90%] sm:max-w-full')}>
        <div className="flex w-full flex-col">
          <span className={cn(titleVariants({
          variant,
          size: fontSize
        }), nowrap ? 'text-nowrap' : '')}>
            {title}
          </span>
          {secondTitle ? <span className={cn(secondTitleVariants({
          variant,
          size: fontSize
        }), nowrap ? 'text-nowrap' : '')}>
              {secondTitle}
            </span> : null}
          <p className={cn(subtitleVariant({
          variant,
          fontSizes: fontSize === 'small' || fontSizeDesktop === 'small' ? 'small' : 'default',
          size: fontSize
        }), subtitleMarginTop ?? 'mt-3')}>
            {subtitle}
          </p>
        </div>
        <div className="flex w-[150px] flex-col-reverse gap-2 self-baseline sm:w-full sm:flex-row sm:items-center sm:justify-start">
          <Button className={cn(buttonVariants({
          variant
        }))} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            {buttonText}
          </Button>
          {message ? <span className={cn(messageVariants({
          variant
        }))}>{message}</span> : null}
        </div>
      </div>
      <Image quality={60} priority={true} alt="banner" src={src as string} height={100} width={100} className={cn('right-0 w-auto self-end', smallImage ? 'absolute max-h-[92px] sm:h-[90px]' : 'relative max-h-[172px] sm:h-[128px]')} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
    </button>;
};