'use client';

import { useSendEvents } from '@monorepo/events/src/negotiation';
import { EventScope, NegotiationEvents } from '@monorepo/events/src/types';
import { useRouter } from '@monorepo/router';
import { useWindowSize } from '@monorepo/utils/hooks';
import Image from '../assets/bannerIcon.svg';
export const useDefaultersBanner = () => {
  const {
    sendBannerClickEvent
  } = useSendEvents(EventScope.HomePage);
  const {
    width
  } = useWindowSize();
  const router = useRouter();
  const onClickBanner = () => {
    router.push('/negociacao/parcelas-vencidas', {
      useWindowOpen: true
    });
    sendBannerClickEvent({
      event: NegotiationEvents.DEFAULTERS_BANNER,
      data: {
        name: 'Banner tela inicial'
      }
    });
  };
  return {
    title: 'Negocie com as melhores condições aqui no meu isaac!',
    subtitle: 'Realize suas negociações com condições imperdíveis, de forma simples e prática!',
    button: 'Negociar agora!',
    src: Image.src,
    titleFontSize: width && width > 635 ? 'small' : 'default',
    subtitleMarginTop: 'mt-2',
    onClickBanner
  };
};