import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import 'dayjs/locale/pt-br';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.locale('pt-br');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

export const months: Record<number, string> = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
};

export const isValid = (date: Dayjs | string | undefined) => {
  if (!date || !dayjs(date).isValid() || date === '0001-01-01T00:00:00Z') {
    return false;
  }
  return true;
};

export const parseToBrazilianTz = (date: Date | Dayjs | string | undefined) =>
  dayjs.tz(date, 'America/Sao_Paulo');

export const parseToBrazilianTimezone = (date: Dayjs | string | undefined) => {
  const fullDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ[Z]');
  return `${fullDate.slice(0, -7)}-03:00`;
};

export const formatMediumDate = (date: Dayjs | string | undefined) => {
  return parseToBrazilianTz(date).format('DD/MM/YY');
};

export const formatFullDate = (
  date: Date | Dayjs | string | undefined,
  format?: string
) => {
  return dayjs(date).format(format ?? 'DD/MM/YYYY');
};

export const addDaysToDate = (date: Date, days: number) => {
  return dayjs(date).add(days, 'day');
};

export const getMonthFromNumber = (
  date: number,
  textLowercase?: boolean
): string => {
  const result = months[date] ?? '-';
  return textLowercase ? result.toString().toLowerCase() : result;
};

export const formatDate = (date: Dayjs | string, format: string) =>
  dayjs(date).format(format);

export const dateRegex =
  /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/;

export const dateRegexValidator = (date: string) => {
  return dateRegex.test(date);
};

export const getMonthNameFromDate = (date: Dayjs | string) => {
  return getMonthFromNumber(dayjs(date).month() + 1);
};

export const formatFullMonthName = (date: Dayjs | string) => {
  return dayjs(date).format('MMMM');
};

export const formatDateWithMonthName = (date: Dayjs | Date | string) => {
  return dayjs(date).format('DD [de] MMMM [de] YYYY');
};

export const formatShortDate = (date: Dayjs | string | undefined | null) => {
  return dayjs(date).format('DD/MM');
};

export const formatShortHour = (date: Dayjs | string) => {
  return dayjs(date).format('HH:mm');
};

export const getMonthsDifference = (
  date1: Dayjs | string,
  date2: Dayjs | string
) => {
  return dayjs(date1).diff(date2, 'month');
};

export const separateDateTime = (date?: Dayjs | string) => {
  if (!date)
    return {
      date: undefined,
      time: undefined,
    };

  return {
    date: formatFullDate(date),
    time: formatShortHour(date),
  };
};
