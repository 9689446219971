'use client';

import type { GetInstallmentV2RouteResponse } from '@monorepo/interfaces';
import { CardButton } from '@srcTailwindUi/components/CardButton';
import { Skeleton } from '@srcTailwindUi/components/Skeleton';
import { useNegotiationButtons } from './useNegotiationButtons';
export const NegotiationButtons = ({
  installmentsData,
  isLoading
}: {
  installmentsData?: GetInstallmentV2RouteResponse;
  isLoading: boolean;
}) => {
  const {
    cardButtons
  } = useNegotiationButtons({
    installmentsData
  });
  if (isLoading) return <Skeleton className="h-28 w-32 transform-none rounded-2xl bg-gray-40 bg-opacity-40" />;
  return <>
      {cardButtons.map(card => <CardButton key={`negotiation-cardButton-box-${card.label}`} Icon={card.Icon} text={card.text} onClick={card.onClick} chipText={card.chipText} />)}
    </>;
};