import type { TRPC_ERROR_CODE_KEY } from './types';

function isObject(value: unknown): value is Record<string, unknown> {
  // check that value is object
  return !!value && !Array.isArray(value) && typeof value === 'object';
}

export function getMessageFromUnknownError(
  err: unknown,
  fallback: string
): string {
  if (typeof err === 'string') {
    return err;
  }
  if (isObject(err) && typeof err.message === 'string') {
    return err.message;
  }
  return fallback;
}

export function getCauseFromUnknown(cause: unknown) {
  if (cause instanceof Error) {
    return cause;
  }

  return undefined;
}

export class ErrorHandler extends Error {
  public readonly cause?: Error;
  public readonly code;

  constructor(opts: {
    message?: string;
    code: TRPC_ERROR_CODE_KEY;
    cause?: unknown;
  }) {
    const message =
      opts.message ?? getMessageFromUnknownError(opts.cause, opts.code);
    super(message, { cause: opts.cause });

    this.code = opts.code;
    this.name = this.constructor.name;
  }
}
