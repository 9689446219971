'use client';

import type { UseEmblaCarouselType } from 'embla-carousel-react';
import * as React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from '@rootTailwindUi/src/components/Carousel/button';
import { cn } from '@srcTailwindUi/lib/utils';
type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];
type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: 'horizontal' | 'vertical';
  setApi?: (api: CarouselApi) => void;
};
type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  scrollTo: (index: number) => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  count: number;
  current: number;
} & CarouselProps;
const CarouselContext = React.createContext<CarouselContextProps | null>(null);
function useCarousel() {
  const context = React.useContext(CarouselContext);
  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }
  return context;
}
const Carousel = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & CarouselProps>(({
  orientation = 'horizontal',
  opts,
  setApi,
  plugins,
  className,
  children,
  ...props
}, ref) => {
  const [carouselRef, api] = useEmblaCarousel({
    ...opts,
    axis: orientation === 'horizontal' ? 'x' : 'y'
  }, plugins);
  const [canScrollPrev, setCanScrollPrev] = React.useState(false);
  const [canScrollNext, setCanScrollNext] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [current, setCurrent] = React.useState(0);
  const onSelect = React.useCallback((api: CarouselApi) => {
    if (!api) {
      return;
    }
    setCanScrollPrev(api.canScrollPrev());
    setCanScrollNext(api.canScrollNext());
    setCurrent(api.selectedScrollSnap());
    setCount(api.scrollSnapList().length);
  }, []);
  const scrollPrev = React.useCallback(() => {
    api?.scrollPrev();
  }, [api]);
  const scrollNext = React.useCallback(() => {
    api?.scrollNext();
  }, [api]);
  const scrollTo = React.useCallback((index: number) => {
    api?.scrollTo(index);
  }, [api]);
  const handleKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowLeft') {
      event.preventDefault();
      scrollPrev();
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      scrollNext();
    }
  }, [scrollPrev, scrollNext]);
  React.useEffect(() => {
    if (!api || !setApi) {
      return;
    }
    setApi(api);
  }, [api, setApi]);
  React.useEffect(() => {
    if (!api) {
      return;
    }
    onSelect(api);
    api.on('reInit', onSelect);
    api.on('select', onSelect);
    setCount(api.scrollSnapList().length);
    return () => {
      api?.off('select', onSelect);
    };
  }, [api, onSelect]);
  return <CarouselContext.Provider value={{
    carouselRef,
    api: api,
    opts,
    orientation: orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
    scrollPrev,
    scrollNext,
    scrollTo,
    canScrollPrev,
    canScrollNext,
    count,
    current
  }}>
        <div ref={ref} onKeyDownCapture={handleKeyDown} className={cn('relative', className)} role="region" aria-roledescription="carousel" {...props}>
          {children}
        </div>
      </CarouselContext.Provider>;
});
Carousel.displayName = 'Carousel';
const CarouselContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({
  className,
  ...props
}, ref) => {
  const {
    carouselRef,
    orientation,
    count
  } = useCarousel();
  return <div ref={carouselRef} className={cn('overflow-hidden', count > 1 ? 'sm:px-2' : '')}>
      <div ref={ref} className={cn('flex', orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col', className)} {...props} />
    </div>;
});
CarouselContent.displayName = 'CarouselContent';
const CarouselItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({
  className,
  ...props
}, ref) => {
  const {
    orientation
  } = useCarousel();
  return <div ref={ref} role="group" aria-roledescription="slide" className={cn('min-w-0 shrink-0 grow-0 basis-full', orientation === 'horizontal' ? 'pl-4' : 'pt-4', className)} {...props} />;
});
CarouselItem.displayName = 'CarouselItem';
const CarouselPrevious = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof Button>>(({
  className,
  variant = 'outline',
  size = 'icon',
  ...props
}, ref) => {
  const {
    orientation,
    scrollPrev,
    canScrollPrev,
    count
  } = useCarousel();
  if (!count || count === 1) return null;
  return <Button ref={ref} variant={variant} size={size} className={cn('absolute flex h-8 w-8 justify-center rounded-full', orientation === 'horizontal' ? '-left-6 top-1/2 -translate-y-1/2' : '-top-6 left-1/2 -translate-x-1/2 rotate-90', className)} disabled={!canScrollPrev} onClick={scrollPrev} {...props}>
      <ChevronLeft className="h-6 w-6" />
    </Button>;
});
CarouselPrevious.displayName = 'CarouselPrevious';
const CarouselNext = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof Button>>(({
  className,
  variant = 'outline',
  size = 'icon',
  ...props
}, ref) => {
  const {
    orientation,
    scrollNext,
    canScrollNext,
    count
  } = useCarousel();
  if (!count || count === 1) return null;
  return <Button ref={ref} variant={variant} size={size} className={cn('absolute flex h-8 w-8 justify-center rounded-full', orientation === 'horizontal' ? '-right-6 top-1/2 -translate-y-1/2' : '-bottom-6 left-1/2 -translate-x-1/2 rotate-90', className)} disabled={!canScrollNext} onClick={scrollNext} {...props}>
      <ChevronRight className="h-6 w-6" />
    </Button>;
});
CarouselNext.displayName = 'CarouselNext';
const CarouselDots = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({
  className,
  ...props
}, ref) => {
  const {
    orientation,
    scrollTo,
    count,
    current
  } = useCarousel();
  if (!count || count === 1) return null;
  return <div className={cn('absolute left-1/2 flex -translate-x-1/2 transform justify-center gap-2', orientation === 'vertical' ? '-right-2 flex-col' : '-bottom-2 flex-row', className)} ref={ref} {...props}>
      {Array.from({
      length: count
    }).map((_, index) => <Button key={`CarouselDots-${index}`} variant="default" size="icon" className={cn('h-2 w-2 rounded-xl bg-blue-300 transition-all duration-500 disabled:w-6 disabled:bg-white disabled:opacity-100')} onClick={() => scrollTo(index)} disabled={index === current} />)}
    </div>;
});
CarouselDots.displayName = 'CarouselDots';
export { Carousel, CarouselContent, CarouselDots, CarouselItem, CarouselNext, CarouselPrevious, type CarouselApi };