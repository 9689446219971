'use client';

import type { LucideIcon } from 'lucide-react';
import { Text } from '@gravity/text';
import type { BadgeProps } from '@rootTailwindUi/src/components/Badge';
import { Badge } from '@rootTailwindUi/src/components/Badge';
import { cn } from '@srcTailwindUi/lib/utils';
export const BarCodeCustomIcon = () => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="BarCodeCustomIcon" data-sentry-source-file="index.tsx">
      <path d="M0.833008 15.8337V4.16699H2.60384V15.8337H0.833008ZM3.33301 15.8337V4.16699H4.99967V15.8337H3.33301ZM5.83301 15.8337V4.16699H6.66634V15.8337H5.83301ZM8.33301 15.8337V4.16699H9.99968V15.8337H8.33301ZM10.833 15.8337V4.16699H13.333V15.8337H10.833ZM14.1663 15.8337V4.16699H14.9997V15.8337H14.1663ZM16.6663 15.8337V4.16699H19.1663V15.8337H16.6663Z" fill="#3D4ED7" data-sentry-element="path" data-sentry-source-file="index.tsx" />
    </svg>;
};
export type CardButtonProps = {
  chipText?: string;
  chipVariant?: BadgeProps['variant'];
  withShadow?: boolean;
  disabled?: boolean;
  loading?: boolean;
  Icon?: LucideIcon | (() => JSX.Element);
  onClick: () => void;
  text?: string;
};
export const CardButton = ({
  chipText,
  chipVariant = 'green',
  withShadow = false,
  disabled = false,
  loading = false,
  Icon,
  onClick,
  text
}: CardButtonProps) => {
  const handleClick = () => {
    if (!disabled && !loading) onClick();
  };
  return <button className={cn('h-31 flex w-32 animate-fadeIn cursor-pointer flex-col rounded-2xl border-[1px] border-solid border-gray-100 bg-white p-4 text-start hover:border-blue-700 hover:bg-blue-200 disabled:cursor-default disabled:bg-gray-50 disabled:hover:border-gray-100 disabled:hover:bg-gray-50', withShadow ? 'shadow-cardButton' : '', loading ? 'pointer-events-none' : 'pointer-events-auto')} onClick={handleClick} data-testid="card-button" data-sentry-component="CardButton" data-sentry-source-file="index.tsx">
      <div className="flex items-center justify-between">
        {Icon ? <Icon className="text-blue-600 disabled:text-gray-200" /> : null}
        {chipText ? <Badge variant={chipVariant} data-testid="card-button-chip">
            {chipText}
          </Badge> : null}
      </div>
      {text ? <Text variant="body-2-regular" className={cn('mt-2 block text-gray-800', disabled ? 'text-gray-300' : '')}>
          {text}
        </Text> : null}
    </button>;
};