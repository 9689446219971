import { cva } from 'class-variance-authority';

export const inputVariants = cva(
  'flex w-full rounded-lg px-3 py-2 font-roboto text-gray-800 placeholder:text-gray-300 disabled:bg-gray-30',
  {
    variants: {
      variant: {
        primary:
          'border border-gray-1000 bg-white hover:border-blue-300 focus:border-blue-600 focus:outline-none',
        soft: 'border border-transparent bg-blue-100 focus:border-blue-300 focus:outline-none',
        error: 'border border-red-300 bg-white text-red-300 focus:outline-none',
      },
      scale: {
        sm: 'h-[24px]',
        md: 'h-[32px]',
        lg: 'h-[40px]',
      },
    },
    defaultVariants: {
      variant: 'primary',
      scale: 'lg',
    },
  }
);
