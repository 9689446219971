import type { ReactNode } from 'react';
import { cn } from '@srcTailwindUi/lib/utils';
type BannerContentProps = {
  children: ReactNode;
  contentWidth?: string;
};
export function BannerContent({
  children,
  contentWidth = 'default'
}: BannerContentProps) {
  return <div className={cn('flex h-full flex-row justify-between gap-2 px-3 pt-4 sm:pl-3 sm:pt-4', contentWidth ? `max-w-[${contentWidth}]` : 'max-w-[90%] sm:max-w-full')} data-sentry-component="BannerContent" data-sentry-source-file="BannerContent.tsx">
      {children}
    </div>;
}