import type { HeadingProps } from '@gravity/heading';
import type { TextProps } from '@gravity/text';
import { Heading } from '@gravity/heading';
import { Text } from '@gravity/text';
const FormTitle = ({
  children,
  ...props
}: HeadingProps) => {
  return <Heading variant="heading-h3-medium" className={'text-gray-800'} color="red" {...props} data-sentry-element="Heading" data-sentry-component="FormTitle" data-sentry-source-file="server-components.tsx">
      {children}
    </Heading>;
};
FormTitle.displayName = 'FormTitle';
const FormSubtitle = ({
  children,
  ...props
}: TextProps) => {
  return <Text className="text-gray-600" {...props} data-sentry-element="Text" data-sentry-component="FormSubtitle" data-sentry-source-file="server-components.tsx">
      {children}
    </Text>;
};
FormSubtitle.displayName = 'FormSubtitle';
export { FormSubtitle, FormTitle };