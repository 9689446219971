'use client';

import React from 'react';
import type { HasContractsToApproveData, InsuranceContractsData } from '@monorepo/interfaces';
import { usePostEvents } from '@monorepo/events';
import { useRouter } from '@monorepo/router';
import { BannerCard } from '@srcTailwindUi/components/BannerCard';
import { useInsuranceBanner } from './useInsuranceBanner';
export const InsuranceBanner = ({
  fidcData,
  insuranceContracts
}: {
  fidcData?: HasContractsToApproveData;
  insuranceContracts?: InsuranceContractsData;
}) => {
  const {
    title,
    subtitle,
    button,
    href,
    showBanner,
    src
  } = useInsuranceBanner({
    fidcData,
    insuranceContracts
  });
  const {
    mutate: postEvent,
    isReady
  } = usePostEvents();
  const router = useRouter();
  React.useEffect(() => {
    if (isReady && showBanner) {
      postEvent({
        app: 'insurance',
        action: 'page_view',
        event: 'page_view_insurance_banner'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, showBanner]);
  if (!showBanner) return null;
  return <BannerCard title={title} subtitle={subtitle} buttonText={button} variant="lightBlue" fontSize="small" smallImage src={src} onClick={() => {
    router.push(href, {
      useWindowOpen: true
    });
    postEvent({
      app: 'insurance',
      event: 'banner',
      action: 'click'
    });
  }} data-sentry-element="BannerCard" data-sentry-component="InsuranceBanner" data-sentry-source-file="index.tsx" />;
};