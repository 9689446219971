'use client';

import * as React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';
import { cn } from '@srcTailwindUi/lib/utils';
const Drawer = ({
  shouldScaleBackground = true,
  ...props
}: React.ComponentProps<typeof DrawerPrimitive.Root>) => <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} data-sentry-element="unknown" data-sentry-component="Drawer" data-sentry-source-file="index.tsx" />;
Drawer.displayName = 'Drawer';
const DrawerTrigger = DrawerPrimitive.Trigger;
const DrawerPortal = DrawerPrimitive.Portal;
const DrawerClose = DrawerPrimitive.Close;
const DrawerOverlay = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Overlay>, React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>>(({
  className,
  ...props
}, ref) => <DrawerPrimitive.Overlay ref={ref} className={cn('fixed inset-0 z-[99999] bg-black/30', className)} {...props} />);
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;
type DrawerContentProps = {
  showDragIndicator?: boolean;
  dragIndicatorBgColor?: string;
} & React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>;
const DrawerContent = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Content>, DrawerContentProps>(({
  className,
  children,
  showDragIndicator = true,
  dragIndicatorBgColor = '',
  ...props
}, ref) => <DrawerPortal>
      <DrawerOverlay />
      <DrawerPrimitive.Content ref={ref} className={cn('fixed inset-x-0 bottom-0 z-[99999] mt-24 flex h-auto flex-col overflow-hidden rounded-t-[10px] border bg-white', className)} {...props}>
        {showDragIndicator ? <div className={cn('px-auto flex h-4 w-full justify-center pt-2', dragIndicatorBgColor)}>
            <div className="h-2 w-[100px] rounded-full bg-gray-100" />
          </div> : null}
        {children}
      </DrawerPrimitive.Content>
    </DrawerPortal>);
DrawerContent.displayName = 'DrawerContent';
const DrawerHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => <div className={cn('grid gap-1.5 p-4 text-center sm:text-left', className)} {...props} data-sentry-component="DrawerHeader" data-sentry-source-file="index.tsx" />;
DrawerHeader.displayName = 'DrawerHeader';
const DrawerFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => <div className={cn('mt-auto flex flex-col gap-2 p-4', className)} {...props} data-sentry-component="DrawerFooter" data-sentry-source-file="index.tsx" />;
DrawerFooter.displayName = 'DrawerFooter';
const DrawerTitle = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Title>, React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>>(({
  className,
  ...props
}, ref) => <DrawerPrimitive.Title ref={ref} className={cn('text-lg font-semibold leading-none tracking-tight', className)} {...props} />);
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;
const DrawerDescription = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Description>, React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>>(({
  className,
  ...props
}, ref) => <DrawerPrimitive.Description ref={ref} className={cn('text-sm text-muted-foreground', className)} {...props} />);
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;
export { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerPortal, DrawerTitle, DrawerTrigger };