import type { ReactNode } from 'react';
import { cn } from '@srcTailwindUi/lib/utils';
type BannerSectionProps = {
  children: ReactNode;
  contentWidth?: string;
};
export function BannerSection({
  children,
  contentWidth = 'default'
}: BannerSectionProps) {
  return <div className={cn('flex max-w-[90%] flex-col', contentWidth ? `max-w-[${contentWidth}]` : 'sm:max-w-[70%]')} data-sentry-component="BannerSection" data-sentry-source-file="BannerSection.tsx">
      {children}
    </div>;
}