import { BannerButton } from './BannerButton';
import { BannerContent } from './BannerContent';
import { BannerFooter } from './BannerFooter';
import { BannerImage } from './BannerImage';
import { BannerRoot } from './BannerRoot';
import { BannerSection } from './BannerSection';
import { BannerSubTitle } from './BannerSubTitle';
import { BannerTitle } from './BannerTitle';
export const Banner = {
  Button: BannerButton,
  Content: BannerContent,
  Title: BannerTitle,
  Subtitle: BannerSubTitle,
  Footer: BannerFooter,
  Root: BannerRoot,
  Image: BannerImage,
  Section: BannerSection
};