import type { VariantProps } from 'class-variance-authority';
import * as React from 'react';
import Image from 'next/image';
import { cva } from 'class-variance-authority';
import { cn } from '@srcTailwindUi/lib/utils';
export const dialogHeaderImageVariants = cva('relative -top-3 -z-[1] flex h-[148px] w-full items-center justify-center sm:top-0 sm:z-0 sm:h-[136px]', {
  variants: {
    variant: {
      error: 'bg-coral-100',
      success: 'bg-green-100',
      warning: 'bg-yellow-100',
      info: 'bg-blue-600'
    }
  },
  defaultVariants: {
    variant: 'info'
  }
});
export type DialogHeaderImageProps = {} & React.ComponentPropsWithoutRef<typeof Image> & VariantProps<typeof dialogHeaderImageVariants>;
const DialogHeaderImage = React.forwardRef<React.ElementRef<typeof Image>, DialogHeaderImageProps>(({
  className,
  variant,
  ...props
}, ref) => <div className={cn(dialogHeaderImageVariants({
  variant
}))}>
    <div className="relative flex h-[88px] w-full">
      <Image priority fill ref={ref} className={cn('object-contain', className)} {...props} />
    </div>
  </div>);
DialogHeaderImage.displayName = 'DialogHeaderImage';
export { DialogHeaderImage };