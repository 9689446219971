'use client';

import dayjs from 'dayjs';

import { formatMediumDate } from '@monorepo/utils/dateFormatters';
import { getMonthName } from '@monorepo/utils/dateHelpers';
import { formatReal } from '@monorepo/utils/numberFormatters';

import type {
  InstallmentEntryItem,
  InstallmentEntryItemStatus,
} from '../types';

export const useInstallmentCard = (
  data?: InstallmentEntryItem,
  monthTitle?: boolean
) => {
  if (!data) return {};

  const stateData = data.status as InstallmentEntryItemStatus;

  const variationByState = {
    OVERDUE: {
      color: 'text-coral-900',
      cardVariant: 'red' as const,
    },
    PAID: {
      color: 'text-green-900',
      cardVariant: 'green' as const,
    },
    OPEN: {
      color: 'text-blue-600',
      cardVariant: 'blue' as const,
    },
    AWAITING: {
      color: 'text-gray-600',
      cardVariant: undefined,
    },
    BROKEN_PROMISE: {
      color: 'text-coral-900',
      cardVariant: undefined,
    },
    EXPIRED: {
      color: 'text-gray-600',
      cardVariant: 'gray' as const,
    },
  };

  const installmentDate = stateData === 'PAID' ? data.paidDate : data.dueDate;

  const formattedDate = formatMediumDate(installmentDate);

  const cardTitle = monthTitle
    ? getMonthName(dayjs(installmentDate).get('month'))
    : data.productName;

  const isOverdue = stateData === 'OPEN' && data.overdue;

  const valueWithoutDueDiscount = data.value + data.dueDiscount;

  const getState = () => {
    if (data.downPaymentWaitingPayment) return 'BROKEN_PROMISE';
    if (data.type === 'RECEIVABLE') {
      if (data.receivableType === 'ENROLLMENT') {
        if (data.expired) return 'EXPIRED';
      }
      if (isOverdue) return 'OVERDUE';
      return stateData;
    }
    return stateData;
  };

  const state = getState();

  const amountColor =
    data.type === 'DOWN_PAYMENT' &&
    stateData === 'OPEN' &&
    !data.downPaymentWaitingPayment
      ? 'text-yellow-900'
      : variationByState[state].color;

  const cardVariant =
    data.receivableType === 'ENROLLMENT'
      ? variationByState[state].cardVariant
      : undefined;

  const dateColor = () => {
    if (state === 'OPEN' || monthTitle) return 'text-gray-600';
    if (state === 'OVERDUE' || state === 'BROKEN_PROMISE')
      return 'text-coral-900';
    return 'text-gray-600';
  };

  const getCardTitleColor = () => {
    if (data.receivableType !== 'ENROLLMENT') {
      return 'text-gray-600';
    }

    if (state === 'PAID') return 'text-gray-600';

    return variationByState[state].color;
  };

  const cardTitleColor = getCardTitleColor();

  const getSubStatus = () => {
    if (data.type === 'DOWN_PAYMENT' && state === 'BROKEN_PROMISE')
      return 'aguardando identificação de pagamento';
    if (data.type === 'RECEIVABLE' && state === 'AWAITING')
      return 'aguardando pagamento de entrada';
  };

  const getType = () => {
    if (data.type === 'DOWN_PAYMENT')
      return `Entrada da Negociação #${data.originNegotiationTag}`;
    if (data.receivableType === 'ENROLLMENT') {
      const type = `Pré-matrícula ${data.contractReferenceYear}`;
      return data.expired ? `${type} - Expirada` : type;
    }
    if (data.originNegotiationTag)
      return `Negociação #${data.originNegotiationTag}`;
    return 'Mensalidade';
  };

  return {
    showInfoStrike: false,
    valueWithoutDueDiscount: formatReal(valueWithoutDueDiscount),
    value: formatReal(data.value),
    formattedDate,
    cardTitle,
    amountColor,
    cardVariant,
    cardTitleColor,
    type: getType(),
    dateColor: dateColor(),
    subStatus: getSubStatus(),
  };
};
