import { cva } from 'class-variance-authority';

export const cardVariants = cva(
  'mx-auto my-0 flex w-full justify-between rounded-lg bg-white p-4 shadow-3 hover:shadow-card-hover hover:drop-shadow-card-hover focus:shadow-card-focus focus:drop-shadow-card-focus active:shadow-card-active',
  {
    variants: {
      variant: {
        yellow: 'border-l-4 border-solid border-l-yellow-900',
        red: 'border-l-4 border-solid border-l-coral-700',
        green: 'border-l-4 border-solid border-l-green-700',
        blue: 'border-l-4 border-solid border-l-blue-600',
        gray: 'border-l-4 border-solid border-l-gray-300',
      },
    },
  }
);
