export const nonNullable = <T>(value: T): value is NonNullable<T> => {
  return value !== null && value !== undefined;
};

export const nonNullableEntries = <T extends Record<string, unknown>>(
  obj: T
): { [K in keyof T]: NonNullable<T[K]> } => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => nonNullable(value))
  ) as { [K in keyof T]: NonNullable<T[K]> };
};

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T; // from lodash

export const truthy = <T>(value: T): value is Truthy<T> => {
  return !!value;
};
