import type { RouteToProps } from '../useRouter';
import { useRouter as useBaseRouter } from '../useRouter';
import { getUrl } from './utils';

export const useFidcRouter = () => {
  const router = useBaseRouter();

  const push = (href: string, ops?: RouteToProps) => {
    return router.push(getUrl(href, router.searchParams), ops);
  };

  return {
    ...router,
    getUrl,
    push,
    showChip: router.searchParams?.get('origin') === 'resumo',
  };
};
