export type CacheData = {
  guardian_ids?: string[];
};

export enum CacheKeys {
  cacheData = '.cache_data',
}

export type UserData = {
  firstAccess: string;
  lastAccess: string;
};

export type DownPaymentBrokenPromise = {
  id: string;
  shortId: string;
  dateShowed?: string;
};

export type AdvancePaymentInstallment = {
  installmentId: string;
  nextViewDate: string;
};

export type NegotiationData = {
  downPaymentsBrokenPromise?: DownPaymentBrokenPromise[];
  advancePaymentInstallments?: AdvancePaymentInstallment[];
};

export enum NegotiationRedisKeys {
  negotiationApp = 'negotiation-app',
}
