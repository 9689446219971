'use client';

import React from 'react';
import { Heading } from '@gravity/heading';
import { Text } from '@gravity/text';
import type { GetInstallmentV2RouteResponse, HasContractsToApproveData } from '@monorepo/interfaces';
import type { NegotiationData } from '@monorepo/interfaces/redis';
import { Button } from '@srcTailwindUi/components/Button';
import { DialogHeaderImage } from '@srcTailwindUi/components/Dialog';
import { ResponsiveDialog } from '@srcTailwindUi/components/ResponsiveDialog';
import errorImage from './assets/errorImage.svg';
import { useNegotiationDialog } from './useNegotiationDialog';
export const NegotiationDialog = ({
  mutateUpdateNegotiationData,
  negotiationData,
  installmentsData,
  fidcData
}: {
  mutateUpdateNegotiationData: (variables: NegotiationData) => void;
  negotiationData?: NegotiationData;
  installmentsData?: GetInstallmentV2RouteResponse;
  fidcData?: HasContractsToApproveData;
}) => {
  const {
    title,
    firstSectionDescription,
    secondSectionDescription,
    buttonNegotiateTitle,
    buttonIgnoreTitle,
    negotiationAlertOpen,
    negotiationAlertClose,
    goToNegotiation,
    isLoadingGoToNegotiation
  } = useNegotiationDialog({
    mutateUpdateNegotiationData,
    negotiationData,
    installmentsData,
    fidcData
  });
  return <ResponsiveDialog open={negotiationAlertOpen} showCloseButton={false} onClose={negotiationAlertClose} dragIndicatorBgColor="bg-coral-100" headerComponent={<DialogHeaderImage src={errorImage.src} alt="negotiation alert header image" variant="error" />} data-sentry-element="ResponsiveDialog" data-sentry-component="NegotiationDialog" data-sentry-source-file="index.tsx">
      <div className="flex w-full flex-col gap-4">
        <div className="flex w-full flex-col gap-2">
          <div className="flex w-full flex-col gap-2">
            <Heading variant="heading-h3-medium" className="mt-4 flex flex-col text-gray-800 sm:flex-row" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
              {title}
            </Heading>
          </div>
          <div className="flex w-full flex-col">
            <Text variant="body-2-regular" className="text-gray-800" data-sentry-element="Text" data-sentry-source-file="index.tsx">
              {firstSectionDescription}
            </Text>
            <Text variant="body-2-regular" className="text-gray-800" data-sentry-element="Text" data-sentry-source-file="index.tsx">
              {secondSectionDescription}
            </Text>
          </div>
        </div>
        <div className="flex w-full flex-col gap-4 sm:relative sm:left-[-5%] sm:w-[110%] sm:flex-row-reverse sm:items-center sm:border-t-[1px] sm:border-solid sm:border-gray-200 sm:p-6 sm:pb-0">
          <Button onClick={goToNegotiation} isLoading={isLoadingGoToNegotiation} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            {buttonNegotiateTitle}
          </Button>
          <Button variant="secondary" onClick={negotiationAlertClose} disabled={isLoadingGoToNegotiation} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            {buttonIgnoreTitle}
          </Button>
        </div>
      </div>
    </ResponsiveDialog>;
};