import type { ReactNode } from 'react';
import { Text } from '@gravity/text';
import { cn } from '@srcTailwindUi/lib/utils';
import type { FontSize, Variant } from './types';
import { subtitleVariant } from './variants';
type BannerSubTitleProps = {
  subtitle: ReactNode;
  variant?: Variant;
  fontSize?: FontSize;
  fontSizeDesktop?: FontSize;
  subtitleMarginTop?: string;
  width?: string;
};
export function BannerSubTitle({
  subtitle,
  variant = 'default',
  fontSize = 'default',
  fontSizeDesktop = 'default',
  subtitleMarginTop,
  width = 'full'
}: BannerSubTitleProps) {
  return <Text className={cn(subtitleVariant({
    variant,
    fontSizes: fontSize === 'small' || fontSizeDesktop === 'small' ? 'small' : 'default',
    size: fontSize
  }), subtitleMarginTop ?? 'mt-3', `sm:w-[${width}]`)} data-sentry-element="Text" data-sentry-component="BannerSubTitle" data-sentry-source-file="BannerSubTitle.tsx">
      {subtitle}
    </Text>;
}