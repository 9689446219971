'use client';

import React from 'react';
import { Heading } from '@gravity/heading';
import { Text } from '@gravity/text';
import { ChevronRightIcon } from '@radix-ui/react-icons';
export type DataCardProps = {
  header: React.ReactNode;
  title: string;
  value?: string;
  subtitle?: string;
  onClick?: () => void;
  id?: string;
};
export const DataCard: React.FC<DataCardProps> = ({
  header,
  title,
  value,
  subtitle,
  onClick
}) => {
  if (!value && !subtitle) return null;
  const content = <div className="flex flex-col items-start gap-2">
      {header}
      <Heading variant="heading-h4-medium" className="text-gray-800">
        {title}
      </Heading>
      <Text className="text-gray-400">{value ?? subtitle}</Text>
    </div>;
  return <button onClick={onClick} className="flex w-full max-w-screen-md cursor-pointer items-center justify-between rounded-lg border border-solid border-opacity-20 p-4 transition duration-300 ease-in hover:bg-black/[.04] hover:ease-out active:bg-black/[.08]" data-sentry-component="DataCard" data-sentry-source-file="index.tsx">
      {content}
      <span className="flex flex-col justify-center gap-2 fill-current text-blue-600">
        <ChevronRightIcon width="24" height="24" data-sentry-element="ChevronRightIcon" data-sentry-source-file="index.tsx" />
      </span>
    </button>;
};