export enum EnrollmentStatus {
  ONDUE_PRECONTRACT_ENROLLMENT = 'ondue-precontract-enrollment',
  ONDUE_PRECONTRACT = 'ondue-precontract',
  ONDUE = 'ondue',
  PROMISE_PRECONTRACT = 'promise-precontract',
  PROMISE = 'promise',
  OVERDUE_PRECONTRACT = 'overdue-precontract',
  OVERDUE = 'overdue',
  ENROLLED = 'enrolled',
  SUSPENDED = 'suspended',
  SUSPENDED_PROMISE = 'suspended-promise',
}

export type StatusEnrollmentType =
  | 'ondue-precontract-enrollment'
  | 'ondue-precontract'
  | 'ondue'
  | 'promise-precontract'
  | 'promise'
  | 'overdue-precontract'
  | 'overdue'
  | 'enrolled'
  | 'suspended'
  | 'suspended-promise';
