const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const formatReal = (number: number) =>
  currencyFormatter.format(number / 100).replace(/\xa0/g, ' ');

export const formatRealToCents = (value: string) => {
  const splitValue = value.split(/\s+/)[0];
  let newValue = 0.0;
  if (splitValue) {
    newValue = parseFloat(splitValue.replace(/\./g, '')?.replace(/,/g, '.'));
  }
  return Number((newValue * 100).toFixed(0));
};

const noFractionDigitsCurrencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 0,
});

export const formatCentsToReal = (v: string | number): string =>
  +v === 0
    ? noFractionDigitsCurrencyFormatter.format(+v).replace(/\xa0/g, ' ')
    : currencyFormatter.format(+v / 100).replace(/\xa0/g, ' ');
