import type { VariantProps } from 'class-variance-authority';
import { Skeleton } from '@srcTailwindUi/components/Skeleton';
import { cn } from '@srcTailwindUi/lib/utils';
import { cardVariants } from './variants';
export type CardVariationsProps = {
  isLoading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement> & VariantProps<typeof cardVariants>;
export const CardVariations = ({
  className,
  variant,
  children,
  isLoading,
  ...props
}: CardVariationsProps) => {
  if (isLoading) return <>
        <div className="mr-8 w-[62%] last:mr-0">
          <Skeleton className="mb-2 h-[0.7rem] w-full rounded-[0.25rem]" />
          <Skeleton className="mb-2 h-[0.7rem] w-1/2 rounded-[0.25rem]" />
        </div>
        <div className="mr-8 w-[38%] last:mr-0">
          <Skeleton className="mb-2 h-[0.7rem] w-full rounded-[0.25rem]" />
          <Skeleton className="mb-2 h-[0.7rem] w-full rounded-[0.25rem]" />
        </div>
      </>;
  return <button className={cn(cardVariants({
    variant
  }), className)} {...props} data-sentry-component="CardVariations" data-sentry-source-file="index.tsx">
      {children}
    </button>;
};