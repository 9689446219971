import type {
  NavigateOptions,
  PrefetchOptions,
} from 'next/dist/shared/lib/app-router-context.shared-runtime';
import type { Params } from 'next/dist/shared/lib/router/utils/route-matcher';
import {
  useRouter as useAppRouter,
  useParams,
  usePathname,
  useSearchParams,
} from 'next/navigation';

import type { RouteToProps } from './types';
import { getUtmSearchParams, getUtmString, stringifyUrl } from './utils';

export const useRouter = <T extends Params>() => {
  const appRouter = useAppRouter();
  const searchParams = useSearchParams();
  const params = useParams<T>();
  const pathname = usePathname();

  const push = (href: string, ops?: RouteToProps) => {
    const options = ops ?? ({} as RouteToProps);

    if (options.useWindowOpen) {
      const target = options.target ?? '_self';
      window.open(
        stringifyUrl({
          href,
          searchParams,
        }),
        target
      );
      return;
    }

    return appRouter.push(stringifyUrl({ href, searchParams }), {
      ...options,
    });
  };

  const replace = (href: string, ops?: NavigateOptions) => {
    return appRouter.replace(stringifyUrl({ href, searchParams }), {
      ...ops,
    });
  };

  const prefetch = (href: string, options?: PrefetchOptions) => {
    return appRouter.prefetch(stringifyUrl({ href, searchParams }), options);
  };

  return {
    ...appRouter,
    back: () => appRouter.back(),
    forward: () => appRouter.forward(),
    refresh: () => appRouter.refresh(),
    push,
    replace,
    prefetch,
    searchParams,
    utmString: getUtmString(searchParams),
    utmParams: getUtmSearchParams(searchParams),
    pathname,
    params,
  };
};

export type { RouteToProps };
