import type { ObjectKeys } from '@monorepo/utils/typescript/objectKeys';

type ParseKey<T extends ObjectKeys<never>> = T extends `$${infer U}`
  ? U
  : `$${T}`;

type ObjectWithDollarSign<T> = T extends readonly unknown[]
  ? { [K in keyof T]: ObjectWithDollarSign<T[K]> }
  : T extends object
    ? {
        [K in ObjectKeys<T> as ParseKey<K>]: ObjectWithDollarSign<T[K]>;
      }
    : T;

export const parseMixPanelProperties = <T>(
  properties: T
): ObjectWithDollarSign<T> | undefined => {
  if (!properties) return;

  if (typeof properties !== 'object')
    return properties as ObjectWithDollarSign<T>;

  if (Array.isArray(properties)) {
    return properties.map(
      (property) => parseMixPanelProperties(property) as ObjectWithDollarSign<T>
    ) as ObjectWithDollarSign<T>;
  }

  const parsedProperties = new Map<`$${string}`, unknown>();

  Object.entries(properties).forEach(([key, value]: [string, unknown]) => {
    parsedProperties.set(
      `$${key.replace('$', '')}`,
      parseMixPanelProperties(value)
    );
  });
  return Object.fromEntries(parsedProperties) as ObjectWithDollarSign<T>;
};
