'use client';

import { useMemo } from 'react';

import type { GetInstallmentV2RouteResponse } from '@monorepo/interfaces';
import { UnleashFlags, useUnleashFlag } from '@monorepo/utils/hooks/unleash';
import { nonNullable } from '@monorepo/utils/typescript/truthyOrNonNullable';

import DefaultersBanner from './DefaultersBanner';
import DiscountCampaignBanner from './DiscountCampaignBanner';
import { useGreetingInvoiceNegotiation } from './useGreetingInvoiceNegotiation';

export const useNegotiationBanners = ({
  installmentsData,
}: {
  installmentsData?: GetInstallmentV2RouteResponse;
}) => {
  const { tuitions } = useGreetingInvoiceNegotiation({ installmentsData });

  const { hasOverdueTuitions } = useMemo(() => {
    return {
      hasOverdueTuitions: tuitions.overdues.length > 0,
    };
  }, [tuitions]);

  const enableDiscountCampaignFlag = useUnleashFlag(
    UnleashFlags.B2CB_850_ENABLE_DISCOUNT_CAMPAIGN
  );

  const enableDiscountCampaignBanner =
    hasOverdueTuitions && enableDiscountCampaignFlag;

  const enableDefaultBanner = hasOverdueTuitions && !enableDiscountCampaignFlag;

  const discountCampaignBanner = DiscountCampaignBanner({
    key: 'discount-campaign-banner',
  });

  const defaultersBanner = DefaultersBanner({ key: 'defaulters-banner' });

  const negotiationBanners = useMemo(() => {
    return Array.from([
      {
        component: enableDiscountCampaignBanner
          ? discountCampaignBanner
          : undefined,
        name: 'discountCampaignBanner',
      },
      {
        component: enableDefaultBanner ? defaultersBanner : undefined,
        name: 'defaultersBanner',
      },
    ]).filter((banner) => nonNullable(banner.component));
  }, [
    enableDiscountCampaignBanner,
    discountCampaignBanner,
    enableDefaultBanner,
    defaultersBanner,
  ]);

  return {
    enableDiscountCampaignBanner,
    enableDefaultBanner,
    negotiationBanners,
  };
};

export { DefaultersBanner, DiscountCampaignBanner };
