import dayjs from 'dayjs';
import groupBy from 'lodash.groupby';
import orderBy from 'lodash.orderby';

import type {
  InstallmentStatus,
  InstallmentTypeV2,
  InstallmentV2,
  ReceivableTypeV2,
} from '@monorepo/interfaces';

import capitalizeFirstLetter from '../capitalizeFirstLetter';
import { isValid } from '../dateFormatters';
import {
  diffDays,
  endOfDay,
  extractMonth,
  extractYear,
  removeTime,
  sanitizeDate,
} from '../dateHelpers';
import { isAwaiting, isOpen, isOverdue, isPaid } from '../filterInstallments';

export type DashboardInstallment = {
  id: string;
  studentName: string;
  dueDate?: string;
  paidDate?: string;
  productName: string;
  originNegotiationTag?: string;
  originNegotiationLabel?: string;
  value: number;
  dueDiscount: number;
  referenceMonth: number;
  referenceYear: number;
  overdue: boolean;
  status: InstallmentStatus;
  type: InstallmentTypeV2;
  receivableType: ReceivableTypeV2;
  expired?: boolean;
  downPaymentWaitingPayment?: boolean;
  contractReferenceYear?: number;
  isFromCreditCardFlow: boolean;
  creditCardFee: number;
};

type GroupField = 'dueDate' | 'paidDate';

type OrderDirection = 'asc' | 'desc';

export const orderInstallments = (
  list: DashboardInstallment[],
  field: GroupField,
  order: OrderDirection
) => {
  return orderBy(list, (el) => dayjs(el[field]).toDate(), [order]);
};

export const formatTitle = (month: number, year: number) => {
  const monthName = dayjs().month(month).format('MMMM');
  const titleDate = dayjs().year(year).format('YYYY');
  return `${capitalizeFirstLetter(monthName)} de ${titleDate}`;
};

export const groupInstallments = (
  list: DashboardInstallment[],
  order: OrderDirection
) => {
  const groupedList = groupBy(
    list,
    (el) => `${el.referenceMonth}-${el.referenceYear}`
  );

  const parsedResult = Object.keys(groupedList).map((key) => {
    const [month, year] = key.split('-');
    const title = formatTitle(Number(month) - 1, Number(year));
    const items = groupedList[key] ?? [];
    return {
      month: Number(month),
      year: Number(year),
      title,
      items,
    };
  });

  return orderBy(
    parsedResult,
    (el) => {
      return dayjs()
        .month(el.month - 1)
        .year(el.year)
        .valueOf();
    },
    [order]
  );
};

export const getOriginNegotiationLabel = (installment: InstallmentV2) => {
  const { origin_negotiation } = installment;
  if (!origin_negotiation) return '';

  const { chosen_payment_plans, resultant_receivables_ids } =
    origin_negotiation;
  if (!chosen_payment_plans) {
    return '';
  }

  const index = resultant_receivables_ids?.findIndex(
    (id) => id === installment.id
  );
  return index === 0
    ? ' - Parcela de entrada'
    : ` - Parcela ${index} de ${Number(resultant_receivables_ids?.length) - 1}`;
};

export const getDashboardInstallment = (
  installment: InstallmentV2
): DashboardInstallment => {
  const {
    id,
    status,
    overdue,
    due_date,
    paid_date,
    product,
    type,
    expired,
    receivable_type,
    origin_negotiation,
    contract,
    down_payment_waiting_payment,
    current_due_payment_discount,
    current_amount,
    student,
    is_from_credit_card_flow,
    credit_card_fee,
  } = installment;
  const tag = origin_negotiation?.short_id.toUpperCase();

  const parsedPaidDate = removeTime(paid_date);
  const parsedDueDate = removeTime(due_date);

  const referenceYear =
    status === 'PAID'
      ? extractYear(parsedPaidDate)
      : extractYear(parsedDueDate);

  const referenceMonth =
    status === 'PAID'
      ? extractMonth(parsedPaidDate)
      : extractMonth(parsedDueDate);

  return {
    id,
    studentName: student?.name,
    dueDate: sanitizeDate(parsedDueDate),
    paidDate: isValid(parsedPaidDate) ? endOfDay(parsedPaidDate) : undefined,
    productName: product,
    originNegotiationTag: tag,
    originNegotiationLabel: getOriginNegotiationLabel(installment),
    value: current_amount,
    dueDiscount: current_due_payment_discount,
    referenceMonth,
    referenceYear,
    status,
    overdue: overdue ?? false,
    type,
    isFromCreditCardFlow: is_from_credit_card_flow,
    creditCardFee: credit_card_fee,
    receivableType: receivable_type,
    expired,
    downPaymentWaitingPayment: down_payment_waiting_payment,
    contractReferenceYear: Number(contract?.reference_year),
  };
};

export const nextInstallmentIdToPay = (
  overdueList: DashboardInstallment[],
  openList: DashboardInstallment[],
  openTodayList: DashboardInstallment[]
) => {
  const nextIdOverdueToPay = orderInstallments(overdueList, 'dueDate', 'asc')[0]
    ?.id;

  const nextIdOpenTodayToPay = orderInstallments(
    openTodayList,
    'dueDate',
    'asc'
  )[0]?.id;

  const nextIdOpenToPay = orderInstallments(openList, 'dueDate', 'asc')[0]?.id;

  if (overdueList.length) {
    return nextIdOverdueToPay;
  } else if (nextIdOpenTodayToPay) {
    return nextIdOpenTodayToPay;
  } else {
    return nextIdOpenToPay;
  }
};

export const nextInstallmentToPay = (
  overdueList: DashboardInstallment[],
  openList: DashboardInstallment[],
  openTodayList: DashboardInstallment[]
) => {
  if (overdueList.length > 0) {
    return orderInstallments(overdueList, 'dueDate', 'asc')[0];
  }
  if (openTodayList.length > 0) {
    return orderInstallments(openTodayList, 'dueDate', 'asc')[0];
  }
  return orderInstallments(openList, 'dueDate', 'asc')[0];
};

export const countInstallmentWithSameDueDate = (
  dueDate: string,
  list: DashboardInstallment[]
) => {
  return list.filter((item) => dayjs(item.dueDate).isSame(dueDate, 'day'))
    .length;
};

export const splitInstallmentsByStatus = (installments: InstallmentV2[]) => {
  const overdues: DashboardInstallment[] = [];
  const allOpens: DashboardInstallment[] = [];
  const opens: DashboardInstallment[] = [];
  const opensToday: DashboardInstallment[] = [];
  const paids: DashboardInstallment[] = [];

  installments.forEach((installment) => {
    const parsedInstallment = getDashboardInstallment(installment);
    if (isPaid(installment)) {
      paids.push(parsedInstallment);
    } else {
      allOpens.push(parsedInstallment);
      if (isOverdue(installment)) {
        overdues.push(parsedInstallment);
      } else if (isOpen(installment) || isAwaiting(installment)) {
        if (diffDays(parsedInstallment.dueDate) == 0) {
          opensToday.push(parsedInstallment);
        }
        opens.push(parsedInstallment);
      }
    }
  });

  return { overdues, opens, opensToday, paids, allOpens };
};

export const splitInstallmentsByStatusAndReceivableType = (
  installments: InstallmentV2[]
) => {
  const enrollments: InstallmentV2[] = [];
  const tuitions: InstallmentV2[] = [];

  installments.forEach((installment) => {
    if (installment.receivable_type === 'ENROLLMENT') {
      enrollments.push(installment);
    } else if (installment.receivable_type === 'TUITION') {
      tuitions.push(installment);
    }
  });

  const splitEnrollments = splitInstallmentsByStatus(enrollments);
  const splitTuitions = splitInstallmentsByStatus(tuitions);

  return { enrollments: splitEnrollments, tuitions: splitTuitions };
};
