'use client';

import React from 'react';
import Image from 'next/image';
import { Text } from '@gravity/text';
import { motion, useScroll } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import type { HeaderIconProps, ProgressHeaderProps } from './types';
import { useAnimation } from './animation';
const HeaderIcon = ({
  src,
  alt
}: HeaderIconProps) => {
  return <Image alt={`header-${alt}-icon`} src={src} height={44} width={40} data-sentry-element="Image" data-sentry-component="HeaderIcon" data-sentry-source-file="view.tsx" />;
};
const ProgressHeader = ({
  title,
  step,
  totalSteps,
  iconSrc,
  animate,
  scrollable,
  fullWidth = false,
  scrollRef
}: ProgressHeaderProps) => {
  const {
    t: tCommon
  } = useTranslation('common');
  const stepperStartWidth = (fullWidth ? 100 : 90) * (step - 1) / totalSteps;
  const stepperFinalWidth = (fullWidth ? 100 : 90) * step / totalSteps;
  const {
    scrollY
  } = useScroll({
    target: scrollRef
  });
  const {
    y,
    opacity
  } = useAnimation(scrollY, scrollable, animate);
  return <motion.div className="fixed left-auto right-0 top-0 z-[999] mt-14 box-border flex h-20 w-full flex-col items-center bg-white shadow-none" style={{
    y
  }} data-sentry-element="unknown" data-sentry-component="ProgressHeader" data-sentry-source-file="view.tsx">
      <div className="flex h-full w-full max-w-[635px] flex-col justify-center">
        <motion.div className="ml-6 flex h-full items-center gap-3" style={{
        opacity
      }} data-sentry-element="unknown" data-sentry-source-file="view.tsx">
          <HeaderIcon src={iconSrc ?? ''} alt={title ?? ''} data-sentry-element="HeaderIcon" data-sentry-source-file="view.tsx" />
          <div className="flex flex-col gap-1">
            <div className="m-0 text-gray-300">
              {tCommon('stepCounter', {
              step,
              totalSteps
            })}
            </div>
            <Text variant="body-2-regular" className="m-0 text-gray-600" data-sentry-element="Text" data-sentry-source-file="view.tsx">
              {title}
            </Text>
          </div>
        </motion.div>
        <div className="h-1 w-full bg-blue-100">
          <motion.div className="h-1 bg-blue-600" initial={{
          width: `${stepperStartWidth}%`
        }} animate={{
          width: `${stepperFinalWidth}%`
        }} transition={{
          duration: 1
        }} data-sentry-element="unknown" data-sentry-source-file="view.tsx" />
        </div>
      </div>
    </motion.div>;
};
export default ProgressHeader;